/**
 *  Formulář žádosti o změnu kontaktních údajů
 */

import React, { useEffect } from "react";

import * as state from "../../../lib/state";
import * as formatting from "../../../lib/formatting";
import * as apiWebRequestsProviders from "../web-requests/api-web-requests-provider";
import * as apiFile from "../../system/file/api-file";

import * as forms from "../../../lib/forms";
import FormCheckBox from "../../../components/controls/Forms/FormCheckBox";
import FormsInput from "../../../components/controls/Forms/FormsInput";
import FileUpload from "../../system/file/FileUpload";
import FormsAutocomplete from "../../../components/controls/Forms/FormsAutocomplete";
import Button from "../../../components/controls/Button";
import CheckBoxRadioFrame from "../../../components/controls/CheckBoxRadioFrame";
import FormRadio from "../../../components/controls/Forms/FormRadio";
import FormsInputDate from "../../../components/controls/Forms/FormsInputDate";
import { Tooltip } from "react-tippy";

interface WebRequestProviderProps {
	form: forms.Form<apiWebRequestsProviders.WebRequestProvider>;
	order: number;
}

export default function WebRequestsProvider(props: WebRequestProviderProps) {
	useEffect(() => {
		const attachments = props.form.getField("attachments").value;
		let files = attachments.slice();
		if (files.length == 0) {
			files.push({ attachment: undefined });
			props.form.setField("attachments", files);
		}
	}, []);

	const { webRequestProvider: model } = state.useStateContext();
	const context = state.useStateContext();
	const form = props.form;
	const workplace = form.getField("workplace").value;

	async function addFile() {
		const attachments = props.form.getField("attachments").value;
		attachments.push({ attachment: undefined });
		props.form.setField("attachments", attachments);
	}

	async function removeFile() {
		const attachments = props.form.getField("attachments").value.filter((_, i) => props.form.getField("attachments").value.length - 1 != i);
		await context.standardDialogs.openYesNoDialog("Odstranit soubor?") === "yes"
			? props.form.setField("attachments", attachments)
			: undefined
	}

	async function setFile(index: number, value: any) {
		const items = props.form.getField("attachments").value;
		let item = items.filter((_, i) => index == i)[0] as any;
		item["attachment"] = value;
		props.form.setField("attachments", items);
	}

	return (
		<>
			<p className="mb-0">
				{props.order == 0 ? "Vyberte poskytovatele zdravotní péče a pracoviště (dle IČO, názvu, adresy), dle kterého Vám bude určena hlavní Oblastní komora." : "Vyberte poskytovatele zdravotní péče a pracoviště (dle IČO, názvu, adresy), dle kterého Vám bude určena hostující Oblastní komora."}
			</p>

			<CheckBoxRadioFrame className="mb-3">
				<div className="row">
					<div className="col-12">
						<FormRadio
							form={form}
							field="request_workplace_type"
							option="existing_workplace"
							title="výběr poskytovatele a pracoviště z nabídky"
						/>
					</div>
					<div className="col-12">
						<FormRadio
							form={form}
							field="request_workplace_type"
							option="not_found"
							title="pokud jste poskytovatele a pracoviště v nabídce nenalezli, založte nového poskytovatele nebo pracoviště"
						/>
					</div>
				</div>
			</CheckBoxRadioFrame>

			{form.getField("request_workplace_type").value == "existing_workplace" &&
				<>
					<FormsAutocomplete
						form={form}
						field="workplace"
						autocompleteProps={{
							trigger: "onfocus",
							loadItems: model.loadWorkplaces,
							getItemText: item => item?.name ?? "",
							getMenuItem: item => item?.name
						}}
					/>
					{workplace &&
						<div className="row mb-3">
							<div className="col-lg-6">
								<div className="row align-items-center">
									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">Poskytovatel</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">{workplace.provider.name}</span>
									</div>

									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">Název pracoviště</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">{workplace.name}</span>
									</div>

									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">Telefon</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">{workplace.contact?.phone1}</span>
									</div>

									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">E-mail</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">{workplace.contact?.email1}</span>
									</div>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="row align-items-center">
									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">IČO</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">{workplace.provider.registration_number}</span>
									</div>

									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">Ulice a číslo</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">{workplace.address?.street}</span>
									</div>

									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">Obec</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">{workplace.address?.city}</span>
									</div>

									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">PSČ</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">{workplace.address?.postcode}</span>
									</div>
								</div>
							</div>
						</div>
					}
				</>
			}

			{form.getField("request_workplace_type").value == "not_found" &&
				<>
					<p className="mt-3">
						Pokud jste poskytovatele zdravotní péče a pracoviště v nabídce nenalezli,
						založte nového poskytovale nebo pracoviště:
					</p>
					<div className="row mt-3 mb-1">
						<div className="col-lg-6">
							<FormsInput form={form} field="workplace_new_provider" />
							<FormsInput form={form} field="workplace_new_ico" />
							<FormsInput form={form} field="workplace_new_provider_address_street" />
							<FormsInput form={form} field="workplace_new_provider_address_city" />
							<FormsInput hideNumeric={true} form={form} inputProps={{ type: "number" }} field="workplace_new_provider_address_postcode" />
						</div>
						<div className="col-lg-6">
							<FormsInput form={form} field="workplace_new_name" />
							<FormsInput form={form} field="workplace_new_address_street" />
							<FormsInput form={form} field="workplace_new_address_city" />
							<FormsInput hideNumeric={true} form={form} inputProps={{ type: "number" }} field="workplace_new_address_postcode" />
							<FormsInput form={form} field="workplace_new_contact_phone" />
							<FormsInput form={form} field="workplace_new_contact_email" />
						</div>
					</div>
					<p className="mt-5 mb-3">
						<strong>
							Přiložte Rozhodnutí o udělení oprávnění k poskytování zdravotních služeb
							z KÚ nebo MHMP (v případě Prahy):
						</strong>
					</p>
					{props.form.getField("attachments").value.map((formFile, index) =>
						<div key={index} className="row">
							<div className={"col-8 col-lg-10 form-item" + ((formFile.attachment == undefined && form.validated() && form.getField("request_workplace_type").value == "not_found") ? " form-item--invalid" : "")}>
								<Tooltip
									className="d-block form-item__tooltip"
									arrow
									position="bottom-start"
									title={(formFile.attachment == undefined && form.validated() && form.getField("request_workplace_type").value == "not_found") ? "Vyplňte přílohu" : undefined}
									trigger="mouseenter"
									disabled={(formFile.attachment == undefined && form.validated() && form.getField("request_workplace_type").value == "not_found") ? false : true}
								>
									<FileUpload
										key={index}
										onChange={(value) => setFile(index, value)}
										value={formFile.attachment}
									/>
								</Tooltip>
							</div>
							<div className="col-4 col-lg-2">
								<Button className="d-block w-100" onClick={() => removeFile()}>Odebrat</Button>
							</div>
						</div>
					)}
					<Button onClick={addFile} className="mb-5">Přidat přílohu</Button>
				</>
			}
			{form.getField("request_workplace_type").value == "not_found" &&
				<div className="row">
					<div className="col-6">
						<FormsInput form={form} field="date_of_entry_into_force" inputProps={{ type: "date" }} />
					</div>
				</div>
			}
			<div><strong>Vztah k poskytovateli (lze označit i více možností):</strong></div>
			<CheckBoxRadioFrame className="mb-3">
				<div className="row">
					<div className="col-lg-6">
						<FormCheckBox form={form} field="relationship_legal_person" />
						<FormCheckBox form={form} field="relationship_statutory_authority" />
						<FormCheckBox form={form} field="relationship_osvc" />
						<FormCheckBox form={form} field="relationship_employee" />
					</div>
					<div className="col-lg-6">
						<FormCheckBox form={form} field="relationship_professional_representative" />
						<FormCheckBox form={form} field="relationship_chief_physician" />
						<FormCheckBox form={form} field="relationship_primary" />
					</div>
				</div>
			</CheckBoxRadioFrame>
			{props.order != 0 &&
				<CheckBoxRadioFrame className="mb-5">
					<div className="row">
						<div className="col-sm-12">
							<FormCheckBox enhancedTitle={<>
								<div className="d-inline">Hostující oblasti - jen pohotovost</div>
								<div>
									<i>Na území této hostující oblastní komory vykonávám pouze pohotovostní služby.</i>
								</div>
							</>} form={form} field="visiting_only_emergency_regional_chambers" />
						</div>
					</div>
				</CheckBoxRadioFrame>
			}

			<div className="row">
				<div className="col-6">
					<FormsInput form={form} field="date_of_validity_of_the_amendments" inputProps={{ type: "date" }} />
				</div>
			</div>

			<strong>
				{props.order == 0 ? "Oznamuji tímto zahájení výkonu povolání (vztah k poskytovateli) na území Oblastní komory:" : "Oznamuji tímto zahájení výkonu povolání (vztah k poskytovateli) na území hostující Oblastní komory:"}
			</strong>
			<div className="row">
				<div className="col-md-6">
					<FormsAutocomplete
						form={form}
						field="host_chamber"
						autocompleteProps={{
							trigger: "onfocus",
							loadItems: model.loadChambers,
							getItemText: item => item?.name ?? "",
							getMenuItem: item => item?.name
						}}
					/>
				</div>
			</div>
			<div>ke dni</div>
			<div className="font-weight-bold text-danger">Pokud datum zahájení výkonu povolání neznáte, poskytovatele</div>
			<div className="font-weight-bold text-danger">zdravotní péče vůbec nevyplňujte. <span className="form-item__required">*</span></div>
			<div className="row">
				<div className="col-md-6">
					<FormsInputDate form={form} field="date" inputProps={{ min: formatting.formatDate(formatting.getDateBefore(new Date(), -14), { format: "iso" }) }} />
				</div>
			</div>
		</ >
	);
}