/**
 * Parsování a formátování.
 */

import numeral from "numeral";
import "numeral/locales";
import moment from "moment";

export function init() {
	numeral.locale("cs");
}

/**
 * Parsuje řetězec jako celé číslo. Vrací: 
 * 		- celé číslo, pokud je řetězec platné celé číslo
 * 		- null, pokud je vstupní řetězec prázdný nebo null
 * 		- NaN, pokud je vstupní řetězec v neplatném formátu
 */
export function parseWholeNumber(value: string | undefined) {
	if (value === undefined || value.trim() == "") {
		return undefined;
	}
	const num = numeral(value).value();
	if (num === null) {
		return NaN;
	} else {
		return num;
	}
}

/**
 *  Parsuje řetězec jako datum.
 */
export function parseDate(value: string) {
	const normalizedValue = value.replace(/ /g, "");
	let mdate = moment(normalizedValue, "D.M.YYYY", true);
	if (!mdate.isValid()) {
		mdate = moment(normalizedValue, moment.ISO_8601);
	}
	return mdate.isValid() ? mdate.toDate() : undefined;
}

/**
 * Zformátuje číslo jako celé číslo (zaokrouhlí případnou desetinou část).
 */
export function formatWholeNumber(value: number | undefined) {
	if (value === undefined) {
		return "";
	} else {
		return numeral(value).format();
	}
}

/**
 * Zformátuje číslo jako měnu.
 */
export function formatCurrency(value: number | undefined, currency?: string) {
	if (value === undefined) {
		return "";
	} else {
		return `${numeral(value).format()} ${currency ? currency : "Kč"}`;
	}
}

/**
 * Naformátuje hodnotu jako procento.
 */
export function formatPercent(value: number | undefined) {
	if (value === undefined) {
		return "";
	} else {
		return numeral(value * 100).format() + " %";
	}
}

/**
 * Naformátuje hodnotu jako datum.
 */
export function formatDate(value: Date | undefined, options?: { format?: "long" | "iso" | "strip" | "strip-long" | "year" }) {
	if (value === undefined) {
		return "";
	} else {
		const now = new Date();
		let format = "D. M. YYYY";

		switch (options?.format) {
			case "iso":
				format = "YYYY-MM-DD";
				break;
			case "long":
				format = "D. MMMM YYYY";
				break;
			case "year":
				format = "YYYY";
				break;
			case "strip":
				if (now.getFullYear() === value.getFullYear())
					format = "D. M."; break;
			case "strip-long":
				format = now.getFullYear() === value.getFullYear() ? "D. MMMM" : "D. MMMM YYYY"; break;
		}

		return moment(value).format(format);
	}
}

/**
 * Naformátuje rozsah dat
 */
export function formatDateRange(dateFrom?: Date, dateTo?: Date, withYear?: boolean) {
	if (!dateFrom) {
		return "";
	}
	else if (dateTo !== undefined) {
		// obě data vyplněná
		if (dateFrom.getDate() === dateTo.getDate()
			&& dateFrom.getMonth() === dateTo.getMonth()
			&& dateFrom.getFullYear() === dateTo.getFullYear()) {

			// obě data stejný den
			return formatDate(dateFrom);
		} else if (dateFrom.getMonth() === dateTo.getMonth()
			&& dateFrom.getFullYear() === dateTo.getFullYear()) {

			// obě data stejný měsíc a rok
			return dateFrom.getDate() + ". - " + formatDate(dateTo);
		} else {
			return formatDate(dateFrom) + " - " + formatDate(dateTo);
		}
	} else {
		return formatDate(dateTo == undefined ? dateFrom : dateTo);
	}
}

/**
 * Naformátuje hodnotu jako datum s časem nebo pouze čas, jedná-li se o dnešek
 */
export function formatDateTime(value: Date | undefined, options?: { format?: "strip" }) {
	if (value === undefined) {
		return "";
	} else {
		const now = new Date();
		let format = "D. M. YYYY H:mm";

		if (options?.format === "strip") {
			if (moment(value).isSame(now, "day")) {
				format = "H:mm";
			} else if (moment(value).isSame(now, "year")) {
				format = "D. M.";
			}
		}

		return moment(value).format(format);
	}
}

export function formatCustomDate(value: Date | undefined, format: string) {
	if (value === undefined) {
		return "";
	} else {
		return moment(value).format(format);
	}
}

export function pluralize(num: number, one: string, twofour: string, zeroOrMore: string) {
	if (num == 1) {
		return one;
	} else if (num >= 2 && num <= 4) {
		return twofour;
	} else if (num == 0 || num >= 5) {
		return zeroOrMore;
	}
}

/**
 * Naformátuje hodnotu jako datum mínus počet dní
 */
export function getDateBefore(value: Date, count: number) {
	return moment(value).days(count).toDate();
}

/**
 * Naformátuje hodnotu jako datum v 0:00
 */
export function setDateToZero(value: Date) {
	const m = moment;
	return moment(value).hours(0).minutes(0).seconds(0).toDate();
}


/**
 * Vygeneruje rodné číslo
 */
export function generateRegistrationNumber(value: Date, genderId: number) {
	const date = formatDate(value, { format: "iso" }).trim();
	return date.substr(2, 2) + (genderId == 1 ? (Number(date.substr(5, 2)) + 50).toString() : date.substr(5, 2)) + date.substr(8, 6) + "/";
}

/**
 * Validace pro rodné číslo
 */
export function identificationNumberValidation(value: string) {
	if (value == "") {
		return "";
	}

	value = value.replace("/", "");

	let rodneCisloResults = {
		ok: 1,
		prilisKratke: 2,
		prilisDlouhe: 3,
		nepripustnaKoncovka000: 4,
		neniDelitelne11: 5,
		nevalidnircecp: 6,
		mesicNevalidni: 7,
		denNevalidni: 8,
		rodneCisloObsahujeJineZnakyNezCislice: 9,
	}

	let ePohlavi = {
		Muz: 1,
		Zena: 2
	}
	let RodneCisloData = function () {
		return { ecp: false, rcplus: false, ok: true, pohlavi: 0, datumNarozeni: new Date() };
	}
	function isNumber(n: any) {
		return /^-?\d+$/.test(n);
	}

	function parseRodneCislo(rodneCislo: any) {
		/// <param name="rodneCislo" type="String">Description</param>
		rodneCislo = rodneCislo.trim();
		if (!isNumber(rodneCislo)) {
			return rodneCisloResults.rodneCisloObsahujeJineZnakyNezCislice;
		}
		if (rodneCislo.length < 9) {
			return rodneCisloResults.prilisKratke;
		}
		let rok = Number(rodneCislo.substr(0, 2));
		let mesic = Number(rodneCislo.substr(2, 2));
		let den = Number(rodneCislo.substr(4, 2));
		let koncovka = rodneCislo.substr(6, rodneCislo.length - 6);

		if (rodneCislo.length > 10) {
			return rodneCisloResults.prilisDlouhe;
		} else if (9 == rodneCislo.length) {
			if (koncovka == "000") {
				return rodneCisloResults.nepripustnaKoncovka000;
			}
		} else {
			// zde je delka rc 10
			if (Number(rodneCislo) % 11 != 0) {
				return rodneCisloResults.neniDelitelne11;
			}
		}

		let data = RodneCisloData();
		data.pohlavi = mesic > 50 ? ePohlavi.Zena : ePohlavi.Muz;
		if (data.pohlavi == ePohlavi.Zena) {
			mesic = mesic - 50;
		}
		if (mesic > 20) {
			data.rcplus = true;
			mesic = mesic - 20;
		}
		if (den > 40) {
			data.ecp = true;
		}
		if (data.ecp && data.rcplus) {
			return rodneCisloResults.nevalidnircecp;
		}

		if (koncovka.length == 3) {
			if (rok > 53) {
				rok += 1800;
			} else {
				rok += 1900;
			}
		} else if (koncovka.length == 4) {
			if (rok > 53) {
				rok += 1900;
			} else {
				rok += 2000;
			}
		}
		if (mesic < 1 || mesic > 12) {
			return rodneCisloResults.mesicNevalidni;
		}
		if (den < 1 || den > 31) {
			return rodneCisloResults.denNevalidni;
		}
		data.datumNarozeni = new Date(rok, mesic - 1, den);
		return data;
	};

	let rc = value;

	let test = parseRodneCislo(rc);

	function check() {
		if (rc != null && rc != "" && !(typeof test === 'object' && test !== null)) {
			let text = "Chyba, kontaktuje Asklepion, nelze pokračovat s tímto rodným číslem";
			switch (test) {
				case 2:
					text = "Rodné číslo je příliš krátké."
					break;
				case 3:
					text = "Rodné číslo je příliš dlouhé."
					break;
				case 4:
					text = "Rodné číslo má špatnou koncovku."
					break;
				case 5:
				case 6:
				case 7:
				case 8:
					text = "Rodné číslo má špatný formát."
					break;
				case 9:
					text = "Rodné číslo jiné znaky než čísla."
					break;
			}
			return text;
		}
		else {
			return "";
		}
	}
	return check();
}

export function decreaseByPercentage(value: number, percentage: number) {
	let decreaseAmount = value * (percentage / 100);
	return value - decreaseAmount;
}

//Funkce pro konfiguraci datumu
export function fromDate(date: Date, fromDays: number, toMonth: number) {
	const dnes = moment(date);
	let minDate;
	let maxDate;

	if (dnes.date() >= fromDays) {
		minDate = dnes.add(1, "months").startOf("month");
	} else {
		minDate = dnes.startOf("month");
	}
	maxDate = moment(minDate).add(toMonth, "months");
	return {
		minDate: minDate.format("YYYY-MM"),
		maxDate: maxDate.format("YYYY-MM")
	};
}