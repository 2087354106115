import * as React from "react";

import * as state from "./lib/state";

// api's
import * as api from "./lib/api";
import * as apiAuth from "./modules/system/authorization/api-authorization";
import * as apiUsers from "./modules/shared/users/api-users";
import * as apiDms from "./modules/shared/dialogs/api-dms";
import * as apiListWorkplaces from "./modules/pages/workplaces/api-workplaces";
import * as apiRecords from "./modules/shared/records/api-records";
import * as apiWorkplace from "./modules/shared/workplace/api-workplace";
import * as apiInformation from "./modules/shared/information/api-information";
import * as apiImages from "./modules/system/images/api-images";
import * as apiEducationalActions from "./modules/shared/educational-actions/api-educational-actions";
import * as apiEducationalPrograms from "./modules/shared/educational-programs/api-educational-programs";
import * as apiCodeList from "./modules/system/code-list/api-code-list";
import * as apiAccreditation from "./modules/shared/accreditation/api-accreditation";
import * as apiWebRequests from "./modules/shared/web-request/api-web-request";
import * as apiExams from "./modules/shared/exams/api-exams";
import * as apiPaymentPrescriptions from "./modules/shared/payment-prescriptions/api-payment-prescriptions";
import * as apiRegionalChambers from "./modules/shared/regional-chambers/api-regional-chambers";
import * as apiFile from "./modules/system/file/api-file";
import * as apiDynamicLists from "./modules/shared/dynamic-lists/api-dynamic-lists";
import * as apiMembers from "./modules/shared/members/api-members";
import * as apiOrgans from "./modules/shared/organs/api-organs";
import * as apiPublications from "./modules/shared/publications/api-publications";
import * as apiAdvertisements from "./modules/shared/advertisements/api-advertisements";
import * as apiShop from "./modules/shared/shop/api-shop";
import * as apiPaymentGateway from "./modules/shared/payment-gateway/api-payment-gateway";
import * as apiCountry from "./modules/shared/country/api-country";

// base modules
import * as authorization from "./modules/system/authorization/authorization";
import * as contents from "./modules/shared/contents/contents";
import * as login from "./modules/system/authorization/login";
import * as loginDifficulties from "./modules/pages/login-difficulties/login-dificulties";
import * as dialogs from "./modules/shared/dialogs/dialogs";
import * as standardDialogs from "./modules/shared/dialogs/standard-dialogs";
import * as dynamicDetail from "./modules/shared/dialogs/dynamic-detail-dialog"
import * as dmsDialog from "./modules/shared/dialogs/dms-dialog"
import * as myEducationalCategory from "./modules/pages/my-educational-actions/my-educational-page-category";
import * as webRequest from "./components/templates/web-request";

// page modules
import * as home from "./modules/pages/home/home";
import * as myRecords from "./modules/pages/my-records/my-records";
import * as webRequests from "./modules/pages/web-requests/web-requests";
import * as myProfile from "./modules/pages/my-profile/my-profile";
import * as myEducation from "./modules/pages/my-education/my-education";
import * as clickableObligation from "./modules/pages/clickable-obligation/clickable-obligation";
import * as changePassword from "./components/sections/change-password";
import * as news from "./modules/pages/news/news";
import * as newsForMembers from "./modules/pages/news/news";
import * as newsDetail from "./modules/pages/news-detail/news-detail";
import * as dynamicListItem from "./modules/pages/dynamic-list-item/dynamic-list-item";
import * as registration from "./modules/pages/registration/registration";
import * as contactsExperts from "./modules/pages/contacts-experts/contacts-experts";
import * as contactOrgans from "./modules/pages/contact-organs/contact-organs"
import * as contactsCsk from "./modules/pages/contacts-csk/contacts-csk";
import * as contactsOsk from "./modules/pages/contacts-osk/contacts-osk";
import * as methodologicalGuidelines from "./modules/pages/methodological_guidelines/methodological-guidelines";
import * as contactsProfessionalCompanies from "./modules/pages/contacts-professional-companies/contacts-professional-companies";
import * as contactsForJournalists from "./modules/pages/contacts-for-journalists/contacts-for-journalists";
import * as aboutUsEstabilishmentRegulations from "./modules/pages/about-us-establishment-regulations/about-us-establishment-regulations";
import * as assemblyMaterials from "./modules/pages/assembly-materials/assembly-materials";
import * as aboutUsYearbooks from "./modules/pages/about-us-yearbooks/about-us-yearbooks";
import * as aboutUsParliamentaryProceedings from "./modules/pages/about-us-parliamentary-proceedings/about-us-parliamentary-proceedings";
import * as content from "./modules/pages/content/content";
import * as aboutUsCouncilMembers from "./modules/pages/about-us-council-members/about-us-council-members";
import * as aboutUsCskAuthority from "./modules/pages/about-us-csk-authority/about-us-csk-authority";
import * as aboutUs from "./modules/pages/about-us/about-us";
import * as dentists from "./modules/pages/dentists/dentists";
import * as dentistsAlphabetical from "./modules/pages/dentists-alphabetical/dentists-alphabetical";
import * as dentistsHostList from "./modules/pages/dentists-host-list/dentists-host-list";
import * as emergencies from "./modules/pages/emergencies/emergencies";
import * as dentist from "./modules/pages/dentist/dentist";
import * as dentistsVisitingGuests from "./modules/pages/dentists-visiting-guests/dentists-visiting-guests";
import * as educationalActions from "./modules/shared/educational-actions/educational-actions";
import * as educationalAction from "./modules/pages/educational-action/educational-action";
import * as workplaces from "./modules/pages/workplaces/workplaces";
import * as meetingOfBoardDirectors from "./modules/pages/agenda-meetings-of-board-directors/agenda-meetings-of-board-directors";
import * as dentalPractice from "./modules/pages/agenda-dental-practice/agenda-dental-practice";
import * as economy from "./modules/pages/agenda-economy/agenda-economy";
import * as myEducationalActions from "./modules/pages/my-educational-actions/my-educational-actions";
import * as myEducationalPrograms from "./modules/pages/my-educational-programs/my-educational-programs";
import * as myMessages from "./modules/pages/my-messages/my-messages";
import * as myDocuments from "./modules/pages/my-documents/my-documents";
import * as documentsSnem from "./modules/pages/documents/documents-snem";
import * as myExams from "./modules/pages/my-exams/my-exams";
import * as myPaymentPrescriptions from "./modules/pages/my-payment-prescriptions/my-payment-prescriptions";
import * as osk from "./modules/pages/osk/osk";
import * as oskMembers from "./modules/pages/osk-members/osk-members";
import * as oskOrgans from "./modules/pages/osk-organs/osk-organs";
import * as oskProfile from "./modules/pages/osk-profile/osk-profile";
import * as oskMaterials from "./modules/pages/osk-materials/osk-materials";
import * as oskMaterialsNegotiations from "./modules/pages/osk-materials-negotiation/osk-materials-negotiation";
import * as oskTransferFees from "./modules/pages/osk-transfer-fees/osk-transfer-fees";
import * as oskTransferFeesItems from "./modules/pages/osk-transfer-fees-items/osk-transfer-fees-items";
import * as oskWebRequests from "./modules/pages/osk-web-requests/osk-web-requests";
import * as oskMessages from "./modules/pages/osk-messages/osk-messages";
import * as oskDocuments from "./modules/pages/osk-documents/osk-documents";
import * as member from "./modules/pages/member/member";
import * as forgottenPassword from "./modules/pages/forgotten-password/forgotten-pasword";
import * as resetPassword from "./modules/pages/reset-password/reset-password";
import * as message from "./modules/pages/message/message";
import * as workplacesList from "./modules/pages/workplaces/workplaces-list";
import * as workplacesListMain from "./modules/pages/workplaces/workplace-list-main";
import * as workplacesListSecondary from "./modules/pages/workplaces/workplace-list-secondary";
import * as publication from "./modules/pages/publication/publication";
import * as publicationArticle from "./modules/pages/publication-article/publication-article";
import * as advertisementsEntry from "./modules/pages/advertisements-entry/advertisements-entry";
import * as advertisements from "./modules/pages/advertisements/advertisements";
import * as books from "./modules/pages/books/books";
import * as booksItem from "./modules/pages/books-item/books-item";
import * as booksCheckout from "./modules/pages/books-checkout/books-checkout";
import * as shopCart from "./modules/shared/shop/shop-cart";
import * as paymentGatewayResponse from "./modules/pages/payment-gateway-response/payment-gateway-response";
import * as webRequestOskCskEntry from "./modules/pages/web-request-osk-csk-entry/web-request-osk-csk-entry";
import * as webRequestOskCskEntry3 from "./modules/pages/web-request-osk-csk-entry/web-request-osk-csk-entry3";
import * as webRequestClinicEducationContribution from "./modules/pages/web-request-clinic-education-contribution/web-request-clinic-education-contribution";
import * as webRequestIssueCertificate from "./modules/pages/web-request-issue-certificate/web-request-issue-certificate";
import * as webRequestIssueFirstCertificate from "./modules/pages/web-request-issue-first-certificate/web-request-issue-first-certificate";
import * as webRequestExtensionOfValidity from "./modules/pages/web-request-extension-of-validity/web-request-extension-of-validity";
import * as webRequestCreditCounting from "./modules/pages/web-request-credit-counting/web-request-credit-counting";
import * as webRequestEntryEduProgram from "./modules/pages/web-request-entry-edu-program/web-request-entry-edu-program";
import * as webRequestExpertsEntry from "./modules/pages/web-request-experts-entry/web-request-experts-entry";
import * as webRequestIssueCertificateAfterEducation from "./modules/pages/web-request-issue-certificate-after-education/web-request-issue-certificate-after-education";
import * as webRequestRepeatedExamInVp from "./modules/pages/web-request-exam-in-vp/web-request-exam-in-vp";
import * as webRequestChangeExamDate from "./modules/pages/web-request-change-exam-date/web-request-change-exam-date";
import * as webRequestMembersRemoving from "./modules/pages/web-request-members-removing/web-request-members-removing";
import * as webRequestMembersConfirmation from "./modules/pages/web-request-members-confirmation/web-request-members-confirmation";
import * as webRequestMembersLeaving from "./modules/pages/web-request-members-leaving/web-request-members-leaving";
import * as webRequestChildCare from "./modules/pages/web-request-child-care/web-request-child-care";
import * as webRequestChildAppeal from "./modules/pages/web-request-for-a-child-appeal/web-request-for-a-child-appeal";
import * as webRequestDisposableEducationalProgramRemovableProsthetics from "./modules/pages/web-request-disposable-educational-program-removable-prosthetics/web-request-disposable-educational-program-removable-prosthetics";
import * as webRequestChangeOfPersonalData from "./modules/pages/web-request-change-of-personal-data/web-request-change-of-personal-data";
import * as webRequestPrescription from "./modules/pages/web-request-prescription/web-request-prescription";
import * as webRequestFeeRemission from "./modules/pages/web-request-fee-remission/web-request-fee-remission";
import * as webRequestLeadDoctorCertificate from "./modules/pages/web-request-issue-certificate/web-request-issue-certificate";
import * as webRequestJobFinish from "./modules/pages/web-request-job-finish/web-request-job-finish";
import * as webRequestJobInterrupt from "./modules/pages/web-request-job-interrupt/web-request-job-interrupt";
import * as webRequestForPerformanceProfessionalExamsWithinTheEntryProgramVppzl from "./modules/pages/web-request-for-performance-professional-exams-within-the-entry-program-vppzl/web-request-for-performance-professional-exams-within-the-entry-program-vppzl";
import * as webRequestForPerformanceProfessionalExamsWithinTheEntryProgramVppzl2023 from "./modules/pages/web-request-for-performance-professional-exams-within-the-entry-program-vppzl2023/web-request-for-performance-professional-exams-within-the-entry-program-vppzl2023";
import * as webRequestForPerformanceProfessionalExamsWithinTheEntryProgramVppzl2023Repeat from "./modules/pages/web-request-for-performance-professional-exams-within-the-entry-program-vppzl2023-repeat/web-request-for-performance-professional-exams-within-the-entry-program-vppzl2023-repeat";
import * as webRequestAboutPerformingProfessionalExamsRepeated10YearsExperienceVppzl from "./modules/pages/web-request-about-performing-professional-exams-repeated-10 years-experience-vppzl/web-request-about-performing-professional-exams-repeated-10 years-experience-vppzl";
import * as webRequestChangeContacts from "./modules/pages/web-request-change-contacts/web-request-change-contacts";
import * as webRequestOskRegistration from "./modules/pages/web-request-osk-registration/web-request-osk-registration";
import * as webRequestOskRegistrationJob from "./modules/pages/web-request-osk-registration-job/web-request-osk-registration-job";
import * as webRequestProvider from "./modules/pages/web-requests/web-requests-provider";
import * as webRequestEducationActionRegistration from "./modules/pages/web-request-education-action-registration/web-request-education-action-registration";
import * as webRequestWebRequestActionElearningRegistration from "./modules/pages/web-request-e-learning-action-registration/web-request-e-learning-action-registration";
import * as webRequestRepetitionOfTheProfessionalExam from "./modules/pages/web-request-repetition-of-the-professional-exam/web-request-repetition-of-the-professional-exam";
import * as webRequestIssueCerificateAfterLifelongEducation from "./modules/pages/web-request-issue-certificate-after-lifelong-education/web-request-issue-certificate-after-lifelong-education";
import * as webRequestWebRequestActionRegistration from "./modules/pages/web-request-action-registration/web-request-action-registration";
import * as webRequestOrderCspzl from "./modules/pages/web-request-order-cspzl/web-request-order-cspzl";
import * as webRequestOrderLks from "./modules/pages/web-request-order-lks/web-request-order-lks";
import * as webRequestAccreditation from "./modules/pages/web-request-accreditation/web-request-accreditation";
import * as webRequestIssueCertificateRepresentative from "./modules/pages/web-request-issue-certificate-representative/web-request-issue-certificate-representative";
import * as workplaceEditPage from "./modules/pages/workplaces/workplace-edit-page";
import * as webRequestInsuranceApplication from "./modules/pages/web-request-insurance-application/web-request-insurance-application"
import * as webRequestCheckoutFromInsurance from "./modules/pages/web-request-checkout-from-insurance/web-request-checkout-from-insurance"
import * as webRequestChangeForm from "./modules/pages/web-request-change-form/web-request-change-form";

export class StateContext {
	hostname: string;

	// some forwarded base modules
	state: state.StateContainerContext;
	dialogs: dialogs.Model;
	standardDialogs: standardDialogs.Model;
	dynamicDetail: dynamicDetail.Model;
	dmsDialog: dmsDialog.Model;

	// api's
	api: api.SpringRestApi;
	apiWorkplace: apiWorkplace.Api;
	apiDms: apiDms.Api;
	apiRecords: apiRecords.Api;
	apiAuth: apiAuth.Api;
	apiInformation: apiInformation.Api;
	apiImages: apiImages.Api;
	apiEducationalActions: apiEducationalActions.Api;
	apiEducationalPrograms: apiEducationalPrograms.Api;
	apiEducationalActionGroups: apiCodeList.Api;
	apiEducationalActionCategories: apiCodeList.ApiSystem;
	apiAccreditationSpecialisations: apiCodeList.Api;
	apiCertificationTypes: apiCodeList.ApiSystem;
	apiMemberRoles: apiCodeList.ApiSystem;
	apiInsuranceCompanies: apiCodeList.Api;
	apiTypeCares: apiCodeList.Api;
	apiRegions: apiCodeList.Api;
	apiUsers: apiUsers.Api;
	apiListWorkplaces: apiListWorkplaces.Api;
	apiAccreditation: apiAccreditation.Api;
	apiWebRequests: apiWebRequests.Api;
	apiExams: apiExams.Api;
	apiPaymentPrescriptions: apiPaymentPrescriptions.Api;
	apiRegionalChambers: apiRegionalChambers.Api;
	apiFile: apiFile.Api;
	apiDynamicLists: apiDynamicLists.Api;
	apiMembers: apiMembers.Api;
	apiOrgans: apiOrgans.Api;
	apiPublications: apiPublications.Api;
	apiAdvertisements: apiAdvertisements.Api;
	apiShop: apiShop.Api;
	apiPaymentGateway: apiPaymentGateway.Api;
	apiCountry: apiCountry.Api;

	// base functionalities models
	authorization: authorization.Model;
	contents: contents.Model;
	login: login.Model;
	loginDifficulties: loginDifficulties.Model;
	webRequest: webRequest.Model;

	// page models
	myRecords: myRecords.Model;
	myProfile: myProfile.Model;
	myEducation: myEducation.Model;
	clickableObligation: clickableObligation.Model;
	changePassword: changePassword.Model;
	home: home.Model;
	news: news.Model;
	newsForMembers: newsForMembers.Model;
	newsDetail: newsDetail.Model;
	dynamicListItem: dynamicListItem.Model;
	registration: registration.Model;
	contactsExperts: contactsExperts.Model;
	contactOrgans: contactOrgans.Model;
	contactsCsk: contactsCsk.Model;
	contactsOsk: contactsOsk.Model;
	methodologicalGuidelines: methodologicalGuidelines.Model;
	contactsProfessionalCompanies: contactsProfessionalCompanies.Model;
	contactsForJournalists: contactsForJournalists.Model;
	aboutUsEstabilishmentRegulations: aboutUsEstabilishmentRegulations.Model;
	assemblyMaterials: assemblyMaterials.Model;
	aboutUsYearbooks: aboutUsYearbooks.Model;
	aboutUsCouncilMembers: aboutUsCouncilMembers.Model;
	aboutUsCskAuthority: aboutUsCskAuthority.Model;
	aboutUs: aboutUs.Model;
	meetingOfBoardDirectors: meetingOfBoardDirectors.Model;
	dentalPractice: dentalPractice.Model;
	economy: economy.Model;
	aboutUsParliamentaryProceedings: aboutUsParliamentaryProceedings.Model;
	content: content.Model;
	dentists: dentists.Model;
	dentistsAlphabetical: dentistsAlphabetical.Model;
	dentistsHostList: dentistsHostList.Model;
	emergencies: emergencies.Model;
	dentist: dentist.Model;
	dentistsVisitingGuests: dentistsVisitingGuests.Model;
	educationalActions: educationalActions.Model;
	educationalAction: educationalAction.Model;
	stages: educationalActions.Model;
	stage: educationalAction.Model;
	workplaces: workplaces.Model;
	webRequests: webRequests.Model;
	myEducationalActions: myEducationalActions.Model;
	myEducationalPrograms: myEducationalPrograms.Model;
	myMessages: myMessages.Model;
	workplacesList: workplacesList.Model;
	workplacesListMain: workplacesListMain.Model;
	workplacesListSecondary: workplacesListSecondary.Model;
	myDocuments: myDocuments.Model;
	documentsSnem: documentsSnem.Model;
	myExams: myExams.Model;
	myPaymentPrescriptions: myPaymentPrescriptions.Model;
	osk: osk.Model;
	oskMembers: oskMembers.Model;
	oskOrgans: oskOrgans.Model;
	oskProfile: oskProfile.Model;
	oskMaterials: oskMaterials.Model;
	oskMaterialsNegotiations: oskMaterialsNegotiations.Model;
	oskTransferFees: oskTransferFees.Model;
	oskTransferFeesItems: oskTransferFeesItems.Model;
	oskWebRequests: oskWebRequests.Model;
	oskMessages: oskMessages.Model;
	oskDocuments: oskDocuments.Model;
	member: member.Model;
	forgottenPassword: forgottenPassword.Model;
	resetPassword: resetPassword.Model;
	message: message.Model;
	publication: publication.Model;
	publicationArticle: publicationArticle.Model;
	advertisements: advertisements.Model;
	advertisementsEntry: advertisementsEntry.Model;
	webRequestOskCskEntry: webRequestOskCskEntry.Model;
	webRequestOskCskEntry3: webRequestOskCskEntry3.Model;
	books: books.Model;
	booksItem: booksItem.Model;
	booksCheckout: booksCheckout.Model;
	shopCart: shopCart.Model;
	paymentGatewayResponse: paymentGatewayResponse.Model;
	webRequestClinicEducationContribution: webRequestClinicEducationContribution.Model;
	webRequestIssueCertificate: webRequestIssueCertificate.Model;
	webRequestIssueFirstCertificate: webRequestIssueFirstCertificate.Model;
	webRequestExtensionOfValidity: webRequestExtensionOfValidity.Model;
	webRequestCreditCounting: webRequestCreditCounting.Model;
	webRequestEntryEduProgram: webRequestEntryEduProgram.Model;
	webRequestExpertsEntry: webRequestExpertsEntry.Model;
	webRequestIssueCertificateAfterEducation: webRequestIssueCertificateAfterEducation.Model;
	webRequestExamInVp: webRequestRepeatedExamInVp.Model;
	webRequestChangeExamDate: webRequestChangeExamDate.Model;
	webRequestMembersRemoving: webRequestMembersRemoving.Model;
	webRequestMembersConfirmation: webRequestMembersConfirmation.Model;
	webRequestMembersLeaving: webRequestMembersLeaving.Model;
	workplaceEditPage: workplaceEditPage.Model;
	webRequestChildCare: webRequestChildCare.Model;
	webRequestChildAppeal: webRequestChildAppeal.Model;
	webRequestDisposableEducationalProgramRemovableProsthetics: webRequestDisposableEducationalProgramRemovableProsthetics.Model;
	webRequestChangeOfPersonalData: webRequestChangeOfPersonalData.Model;
	webRequestPrescription: webRequestPrescription.Model;
	webRequestFeeRemission: webRequestFeeRemission.Model;
	webRequestLeadDoctorCertificate: webRequestLeadDoctorCertificate.Model;
	webRequestJobFinish: webRequestJobFinish.Model;
	webRequestJobInterrupt: webRequestJobInterrupt.Model;
	webRequestForPerformanceProfessionalExamsWithinTheEntryProgramVppzl: webRequestForPerformanceProfessionalExamsWithinTheEntryProgramVppzl.Model;
	webRequestForPerformanceProfessionalExamsWithinTheEntryProgramVppzl2023: webRequestForPerformanceProfessionalExamsWithinTheEntryProgramVppzl2023.Model;
	WebRequestForPerformanceProfessionalExamsWithinTheEntryProgramVppzl2023Repeat: webRequestForPerformanceProfessionalExamsWithinTheEntryProgramVppzl2023Repeat.Model;
	webRequestAboutPerformingProfessionalExamsRepeated10YearsExperienceVppzl: webRequestAboutPerformingProfessionalExamsRepeated10YearsExperienceVppzl.Model;
	webRequestChangeContacts: webRequestChangeContacts.Model;
	webRequestOskRegistration: webRequestOskRegistration.Model;
	webRequestOskRegistrationJob: webRequestOskRegistrationJob.Model;
	webRequestProvider: webRequestProvider.Model;
	webRequestEducationActionRegistration: webRequestEducationActionRegistration.Model;
	webRequestWebRequestActionElearningRegistration: webRequestWebRequestActionElearningRegistration.Model;
	webRequestRepetitionOfTheProfessionalExam: webRequestRepetitionOfTheProfessionalExam.Model;
	webRequestIssueCerificateAfterLifelongEducation: webRequestIssueCerificateAfterLifelongEducation.Model;
	webRequestWebRequestActionRegistration: webRequestWebRequestActionRegistration.Model;
	webRequestOrderCspzl: webRequestOrderCspzl.Model;
	webRequestOrderLks: webRequestOrderLks.Model;
	webRequestAccreditation: webRequestAccreditation.Model;
	webRequestIssueCertificateRepresentative: webRequestIssueCertificateRepresentative.Model;
	myEducationalCategory: myEducationalCategory.Model;
	webRequestInsuranceApplication: webRequestInsuranceApplication.Model;
	webRequestCheckoutFromInsurance: webRequestCheckoutFromInsurance.Model;
	webRequestChangeForm: webRequestChangeForm.Model;

	constructor(hostname: string) {
		this.hostname = hostname;

		// some forwarded base modules
		this.state = new state.StateContainerContext();
		this.dialogs = new dialogs.Model(this);
		this.contents = new contents.Model(this);
		this.standardDialogs = new standardDialogs.Model(this);
		this.dynamicDetail = new dynamicDetail.Model(this);

		// api's
		this.api = new api.SpringRestApi({
			apiKey: "60a009a4-ac62-4afc-ad13-0fa266c99f35",
			endpoint: this.hostname === "dent.ssapp.cz" ? "https://dent-api.ssapp.cz/api/v1/web" :
				(this.hostname === "pdd.dent.cz" || this.hostname === "osf.dent.cz" || this.hostname === "psf.dent.cz" || this.hostname === "csbrsd.dent.cz" || this.hostname === "dent.cz" || this.hostname === "www.dent.cz")
					? "https://is-api.dent.cz/api/v1/web"
					: "https://csk-api.springsystems.cz/api/v1/web",
			context: this
		});

		this.apiWorkplace = new apiWorkplace.Api(this);
		this.apiDms = new apiDms.Api(this);
		this.dmsDialog = new dmsDialog.Model(this);
		this.apiRecords = new apiRecords.Api(this);
		this.apiAuth = new apiAuth.Api(this);
		this.apiInformation = new apiInformation.Api(this);
		this.apiImages = new apiImages.Api(this);
		this.apiEducationalActions = new apiEducationalActions.Api(this);
		this.apiEducationalPrograms = new apiEducationalPrograms.Api(this);
		this.apiEducationalActionGroups = new apiCodeList.Api(this, "educational_action_groups");
		this.apiEducationalActionCategories = new apiCodeList.ApiSystem(this, "educational_action_categories");
		this.apiAccreditationSpecialisations = new apiCodeList.Api(this, "accreditation_specialisations");
		this.apiListWorkplaces = new apiListWorkplaces.Api(this);
		this.apiCertificationTypes = new apiCodeList.ApiSystem(this, "certificate_types");
		this.apiMemberRoles = new apiCodeList.ApiSystem(this, "member_roles");
		this.apiInsuranceCompanies = new apiCodeList.Api(this, "insurance_companies");
		this.apiTypeCares = new apiCodeList.Api(this, "type_cares");
		this.apiRegions = new apiCodeList.Api(this, "regions");
		this.apiUsers = new apiUsers.Api(this);
		this.apiAccreditation = new apiAccreditation.Api(this);
		this.apiWebRequests = new apiWebRequests.Api(this);
		this.apiExams = new apiExams.Api(this);
		this.apiPaymentPrescriptions = new apiPaymentPrescriptions.Api(this);
		this.apiRegionalChambers = new apiRegionalChambers.Api(this);
		this.apiFile = new apiFile.Api(this);
		this.apiDynamicLists = new apiDynamicLists.Api(this);
		this.apiMembers = new apiMembers.Api(this);
		this.apiOrgans = new apiOrgans.Api(this);
		this.apiPublications = new apiPublications.Api(this);
		this.apiAdvertisements = new apiAdvertisements.Api(this);
		this.apiShop = new apiShop.Api(this);
		this.apiPaymentGateway = new apiPaymentGateway.Api(this);
		this.apiCountry = new apiCountry.Api(this);

		// base functionalities models
		this.authorization = new authorization.Model(this);
		this.login = new login.Model(this);
		this.loginDifficulties = new loginDifficulties.Model(this);
		this.webRequest = new webRequest.Model(this);

		// page models
		this.myRecords = new myRecords.Model(this);
		this.myProfile = new myProfile.Model(this);
		this.myEducation = new myEducation.Model(this);
		this.clickableObligation = new clickableObligation.Model(this);
		this.changePassword = new changePassword.Model(this);
		this.home = new home.Model(this);
		this.news = new news.Model(this, false);
		this.newsForMembers = new newsForMembers.Model(this, true);
		this.newsDetail = new newsDetail.Model(this);
		this.dynamicListItem = new dynamicListItem.Model(this);
		this.registration = new registration.Model(this);
		this.contactsExperts = new contactsExperts.Model(this);
		this.contactOrgans = new contactOrgans.Model(this);
		this.contactsCsk = new contactsCsk.Model(this);
		this.contactsOsk = new contactsOsk.Model(this);
		this.methodologicalGuidelines = new methodologicalGuidelines.Model(this);
		this.contactsProfessionalCompanies = new contactsProfessionalCompanies.Model(this);
		this.contactsForJournalists = new contactsForJournalists.Model(this);
		this.aboutUsEstabilishmentRegulations = new aboutUsEstabilishmentRegulations.Model(this);
		this.assemblyMaterials = new assemblyMaterials.Model(this);
		this.aboutUsYearbooks = new aboutUsYearbooks.Model(this);
		this.aboutUsCouncilMembers = new aboutUsCouncilMembers.Model(this);
		this.aboutUsCskAuthority = new aboutUsCskAuthority.Model(this);
		this.meetingOfBoardDirectors = new meetingOfBoardDirectors.Model(this);
		this.dentalPractice = new dentalPractice.Model(this);
		this.economy = new economy.Model(this);
		this.aboutUsParliamentaryProceedings = new aboutUsParliamentaryProceedings.Model(this);
		this.content = new content.Model(this);
		this.aboutUs = new aboutUs.Model(this);
		this.dentists = new dentists.Model(this);
		this.dentistsAlphabetical = new dentistsAlphabetical.Model(this);
		this.dentistsHostList = new dentistsHostList.Model(this);
		this.emergencies = new emergencies.Model(this);
		this.dentist = new dentist.Model(this);
		this.dentistsVisitingGuests = new dentistsVisitingGuests.Model(this);
		this.educationalActions = new educationalActions.Model(this);
		this.educationalAction = new educationalAction.Model(this);
		this.stages = new educationalActions.Model(this, { stages: true });
		this.stage = new educationalAction.Model(this);
		this.workplaces = new workplaces.Model(this);
		this.webRequests = new webRequests.Model(this);
		this.myEducationalActions = new myEducationalActions.Model(this);
		this.myEducationalPrograms = new myEducationalPrograms.Model(this);
		this.myMessages = new myMessages.Model(this);
		this.workplacesList = new workplacesList.Model(this);
		this.workplacesListMain = new workplacesListMain.Model(this);
		this.workplacesListSecondary = new workplacesListSecondary.Model(this);
		this.myDocuments = new myDocuments.Model(this);
		this.documentsSnem = new documentsSnem.Model(this);
		this.myExams = new myExams.Model(this);
		this.myPaymentPrescriptions = new myPaymentPrescriptions.Model(this);
		this.osk = new osk.Model(this);
		this.oskMembers = new oskMembers.Model(this);
		this.oskOrgans = new oskOrgans.Model(this);
		this.oskProfile = new oskProfile.Model(this);
		this.oskMaterials = new oskMaterials.Model(this);
		this.oskMaterialsNegotiations = new oskMaterialsNegotiations.Model(this);
		this.oskTransferFees = new oskTransferFees.Model(this);
		this.oskTransferFeesItems = new oskTransferFeesItems.Model(this);
		this.oskWebRequests = new oskWebRequests.Model(this);
		this.oskMessages = new oskMessages.Model(this);
		this.oskDocuments = new oskDocuments.Model(this);
		this.member = new member.Model(this);
		this.forgottenPassword = new forgottenPassword.Model(this);
		this.resetPassword = new resetPassword.Model(this);
		this.message = new message.Model(this);
		this.publication = new publication.Model(this);
		this.publicationArticle = new publicationArticle.Model(this);
		this.advertisements = new advertisements.Model(this);
		this.advertisementsEntry = new advertisementsEntry.Model(this);
		this.webRequestOskCskEntry = new webRequestOskCskEntry.Model(this);
		this.webRequestOskCskEntry3 = new webRequestOskCskEntry3.Model(this);
		this.books = new books.Model(this);
		this.booksItem = new booksItem.Model(this);
		this.booksCheckout = new booksCheckout.Model(this);
		this.shopCart = new shopCart.Model(this);
		this.paymentGatewayResponse = new paymentGatewayResponse.Model(this);
		this.webRequestClinicEducationContribution = new webRequestClinicEducationContribution.Model(this);
		this.webRequestIssueCertificate = new webRequestIssueCertificate.Model(this);
		this.webRequestIssueFirstCertificate = new webRequestIssueFirstCertificate.Model(this);
		this.webRequestExtensionOfValidity = new webRequestExtensionOfValidity.Model(this);
		this.webRequestCreditCounting = new webRequestCreditCounting.Model(this);
		this.webRequestEntryEduProgram = new webRequestEntryEduProgram.Model(this);
		this.webRequestExpertsEntry = new webRequestExpertsEntry.Model(this);
		this.webRequestIssueCertificateAfterEducation = new webRequestIssueCertificateAfterEducation.Model(this);
		this.webRequestExamInVp = new webRequestRepeatedExamInVp.Model(this);
		this.webRequestChangeExamDate = new webRequestChangeExamDate.Model(this);
		this.webRequestMembersRemoving = new webRequestMembersRemoving.Model(this);
		this.webRequestMembersConfirmation = new webRequestMembersConfirmation.Model(this);
		this.webRequestMembersLeaving = new webRequestMembersLeaving.Model(this);
		this.workplaceEditPage = new workplaceEditPage.Model(this);
		this.webRequestChildCare = new webRequestChildCare.Model(this);
		this.webRequestChildAppeal = new webRequestChildAppeal.Model(this);
		this.webRequestDisposableEducationalProgramRemovableProsthetics = new webRequestDisposableEducationalProgramRemovableProsthetics.Model(this);
		this.webRequestChangeOfPersonalData = new webRequestChangeOfPersonalData.Model(this);
		this.webRequestPrescription = new webRequestPrescription.Model(this);
		this.webRequestFeeRemission = new webRequestFeeRemission.Model(this);
		this.webRequestLeadDoctorCertificate = new webRequestLeadDoctorCertificate.Model(this);
		this.webRequestJobFinish = new webRequestJobFinish.Model(this);
		this.webRequestJobInterrupt = new webRequestJobInterrupt.Model(this);
		this.webRequestForPerformanceProfessionalExamsWithinTheEntryProgramVppzl = new webRequestForPerformanceProfessionalExamsWithinTheEntryProgramVppzl.Model(this);
		this.webRequestForPerformanceProfessionalExamsWithinTheEntryProgramVppzl2023 = new webRequestForPerformanceProfessionalExamsWithinTheEntryProgramVppzl2023.Model(this);
		this.WebRequestForPerformanceProfessionalExamsWithinTheEntryProgramVppzl2023Repeat = new webRequestForPerformanceProfessionalExamsWithinTheEntryProgramVppzl2023Repeat.Model(this);
		this.webRequestAboutPerformingProfessionalExamsRepeated10YearsExperienceVppzl = new webRequestAboutPerformingProfessionalExamsRepeated10YearsExperienceVppzl.Model(this);
		this.webRequestChangeContacts = new webRequestChangeContacts.Model(this);
		this.webRequestOskRegistration = new webRequestOskRegistration.Model(this);
		this.webRequestOskRegistrationJob = new webRequestOskRegistrationJob.Model(this);
		this.webRequestProvider = new webRequestProvider.Model(this);
		this.webRequestEducationActionRegistration = new webRequestEducationActionRegistration.Model(this);
		this.webRequestWebRequestActionElearningRegistration = new webRequestWebRequestActionElearningRegistration.Model(this);
		this.webRequestRepetitionOfTheProfessionalExam = new webRequestRepetitionOfTheProfessionalExam.Model(this);
		this.webRequestIssueCerificateAfterLifelongEducation = new webRequestIssueCerificateAfterLifelongEducation.Model(this);
		this.webRequestWebRequestActionRegistration = new webRequestWebRequestActionRegistration.Model(this);
		this.webRequestOrderCspzl = new webRequestOrderCspzl.Model(this);
		this.webRequestOrderLks = new webRequestOrderLks.Model(this);
		this.webRequestAccreditation = new webRequestAccreditation.Model(this);
		this.webRequestIssueCertificateRepresentative = new webRequestIssueCertificateRepresentative.Model(this);
		this.myEducationalCategory = new myEducationalCategory.Model(this);
		this.webRequestInsuranceApplication = new webRequestInsuranceApplication.Model(this);
		this.webRequestCheckoutFromInsurance = new webRequestCheckoutFromInsurance.Model(this);
		this.webRequestChangeForm = new webRequestChangeForm.Model(this);
	}
}

const AppContext = React.createContext<StateContext>(undefined as any);
export default AppContext;
