/**
 *  Formulář žádosti o zápis do seznamu ČSK a OSK
 */
import React from "react";

import * as forms from "../../../lib/forms";
import * as webRequestOskCskEntry3 from "./web-request-osk-csk-entry3";
import * as state from "../../../lib/state";
import * as formatting from "../../../lib/formatting";

import WebRequest from "../../../components/templates/WebRequest";
import FormsInput from "../../../components/controls/Forms/FormsInput";
import FormRadio from "../../../components/controls/Forms/FormRadio";
import FormCheckBox from "../../../components/controls/Forms/FormCheckBox";
import FormSelect from "../../../components/controls/Forms/FormSelect";
import WebRequestsProvider from "../web-requests/WebRequestsProvider";
import Button from "../../../components/controls/Button";
import CheckBoxRadioFrame from "../../../components/controls/CheckBoxRadioFrame";
import FormFileUpload from "../../system/file/FormFileUpload";
import IconSvg from "../../../components/controls/IconSvg";
import FormsRadio from "../../../components/controls/Forms/FormRadio";

interface AddressProps {
	form: forms.Form<webRequestOskCskEntry3.AddressFormFields>;
	autofill?: boolean;
	isContact?: boolean;
}

function Address(props: AddressProps) {
	const { webRequestOskCskEntry3: model } = state.useStateContext();
	return (
		<>
			<FormsInput form={props.form} field="street" inputProps={{ name: props.autofill ? "street" : "" }} />
			<FormsInput form={props.form} field="city" inputProps={{ name: props.autofill ? "city" : "" }} />
			<FormsInput form={props.form} field="zip" inputProps={{ type: "number", name: props.autofill ? "zip" : "" }} />
		</>
	);
}

interface WebRequestOskCskEntryPage3Props {
	id?: string;
	restore?: boolean;
}


function WebRequestOskCskEntryPage3(props: WebRequestOskCskEntryPage3Props) {
	const title = "ŽÁDOST O OBNOVENÍ ZÁPISU DO SEZNAMU ČLENŮ ČSK A OSK (obnovení ukončeného členství)";
	const { webRequestOskCskEntry3: model } = state.useStateContext();
	const { authorization } = state.useStateContext();
	const formEntry = model.form;
	const formAddress = model.formAddress;
	const formContactAddress = model.formContactAddress;

	return (
		<WebRequest
			title={title}
			description=""
			authorized={false}
			model={model.common}
			id={props.id}
		>

			{props.restore &&
				<em>Zkontrolujte a opravte údaje, které chcete aktualizovat.</em>
			}
			{formEntry.getField("loaded") &&
				<>
					<p>Vaše členství v ČSK zaniklo dne {formatting.formatDate(authorization.getUserProfile()?.profile.membership_to)}. Pro obnovení ukončeného členství v ČSK je třeba zkontrolovat a aktualizovat všechny níže
						uvedené údaje.</p>
					<h2 className="mb-5">Identifikační údaje:</h2>

					<div className="row mb-5">
						<div className="col-md-6">
							<FormSelect form={formEntry} field="title_before" options={
								model.getTitlesBefore().map(i => ({
									title: i.name,
									value: i
								}))
							} />
							<FormSelect form={formEntry} field="edu_title" options={
								model.getTitlesPedagogical().map(i => ({
									title: i.name,
									value: i
								}))
							} />
							<FormSelect form={formEntry} field="title_after" options={
								model.getTitlesAfter().map(i => ({
									title: i.name,
									value: i
								}))
							} />
							<FormsInput inputProps={{ type: "date" }} form={formEntry} field="birth_date" />
							<div>
								<p className="mt-0 mb-0">
									Pohlaví
								</p>
								<div className="row">
									<div className="col-md-6">
										<FormRadio form={formEntry} field="gender" option="female" title="Ženské" />
									</div>
									<div className="col-md-6">
										<FormRadio form={formEntry} field="gender" option="male" title="Mužské" />
									</div>
								</div>
							</div>
							<FormsInput form={formEntry} field="rc" />
						</div>
						<div className="col-md-6">
							<FormsInput form={formEntry} field="first_name" />
							<FormsInput form={formEntry} field="last_name" />

							<FormSelect
								form={formEntry}
								field="nationality"
								options={
									model.getCountries().map(i => ({
										title: i.name,
										value: i
									}))
								} />
							{formEntry.getField("nationality").value?.code != "CZ" as any &&
								<FormsInput form={formEntry} inputProps={{ type: "date" }} field="validity_of_stay" />
							}
						</div>
					</div>

					<div className="row">
						<div className="col-md-6">
							<h2>Adresa pobytu na území ČR:</h2>
							<div className="font-weight-bold text-danger">Neuvádějte, prosím, adresu do zahraničí, nebude akceptována.</div>
							<p className="mb-5">&nbsp;</p>
							<Address form={formAddress} autofill />
							<FormsInput form={formEntry} field="phone" />
							<FormsInput form={formEntry} field="email" />
						</div>
						<div className="col-md-6">
							<h2>Adresa pro doručení na území ČR:</h2>
							<div className="font-weight-bold text-danger">Neuvádějte, prosím, adresu do zahraničí, nebude akceptována.</div>
							<p className="mb-5"><em>(kontaktní adresa, uveďte pouze pokud se liší od adresy pobytu)</em></p>
							<Address form={formContactAddress} isContact={true} />
						</div>
					</div>

					<h2 className="mb-5">Dosažené vysokoškolské vzdělání:</h2>
					<div className="row">
						{formEntry.getField("certificated_in").value != "cr" &&
							<div className="col-md-6">
								<FormsInput form={formEntry} field="university_name" />
							</div>
						}
						{formEntry.getField("certificated_in").value == "cr" &&
							<div className="col-md-6">
								<FormSelect
									form={formEntry}
									field="university_name"
									options={
										[
											{ title: "Vyberte jednu z možností...", value: "" },
											{ title: "LF UK Praha", value: "LF UK Praha" },
											{ title: "1. LF UK Praha", value: "1. LF UK Praha" },
											{ title: "LF UK v Hradci Králové", value: "LF UK v Hradci Králové" },
											{ title: "LF UK v Plzni", value: "LF UK v Plzni" },
											{ title: "LF UP Olomouc", value: "LF UP Olomouc" },
											{ title: "LF MU Brno", value: "LF MU Brno" },
											{ title: "SK - LF UK v Bratislave", value: "SK - LF UK v Bratislave" },
											{ title: "SK - LF UPJŠ v Košiciach", value: "SK - LF UPJŠ v Košiciach" },
											{ title: "SK - JLF UK v Martine", value: "SK - JLF UK v Martine" },
											{ title: "Jiná", value: "Jiná" }
										]
									} />
							</div>
						}
						<div className="col-md-6">
							<FormsInput inputProps={{ type: "date", max: formatting.formatDate(new Date(), { format: "iso" }) }} form={formEntry} field="graduated_date" />
						</div>
						{formEntry.getField("university_name").value == "Jiná" &&
							<div className="col-md-6">
								<FormsInput form={formEntry} field="university_custom" />
							</div>
						}
						<div className="col-md-6">
							<FormsInput form={formEntry} field="graduated_diploma_number" />
						</div>
					</div>

					<h2 className="mb-0">Specializace dle zákona č. 95/2004 Sb.:</h2>
					<p className="mb-3">
						<em>Vyberte pouze v případě získání uvedené specializace/í.</em>
					</p>
					<CheckBoxRadioFrame readOnly={formEntry.isReadOnly()}>
						<div className="row">
							<div className="col-md-6">
								<FormCheckBox form={formEntry} field="specialization_ortodoncy" />
							</div>
							<div className="col-md-6">
								<FormCheckBox form={formEntry} field="specialization_clinic_stomatology" />
							</div>
							<div className="col-md-6">
								<FormCheckBox form={formEntry} field="specialization_surgery" />
							</div>
						</div>
					</CheckBoxRadioFrame>

					<h2 className="mb-0">Další dosažené vzdělání:</h2>
					<p className="mb-3">
						<em>Vyberte pouze v případě získání dalšího dosaženého vzdělání.</em>
					</p>
					<CheckBoxRadioFrame readOnly={formEntry.isReadOnly()} className="mb-5">
						<div className="row">
							<div className="col-md-6">
								<FormCheckBox form={formEntry} field="next_education_atest_1" />
								<FormCheckBox form={formEntry} field="next_education_atest_2" />
								<FormCheckBox form={formEntry} field="next_education_ext_surgery" />
								<FormCheckBox form={formEntry} field="next_education_ext_children_stoma" />
							</div>
							<div className="col-md-6">
								<FormCheckBox form={formEntry} field="next_education_ext_parodontology" />
								<FormCheckBox form={formEntry} field="next_education_ext_social_medicine" />
								<FormCheckBox form={formEntry} field="next_education_ext_prosthetics" />
							</div>
						</div>
					</CheckBoxRadioFrame>
					<div className="row">
						<div className="col-md-6">
							<FormsInput form={formEntry} field="next_education_other" />
						</div>
					</div>
					<h2 className="mb-0">Určení příslušnosti k Oblastní komoře:</h2>
					<p className="mb-3">
						<em>Každý člen ČSK je zapsán do seznamu členů Oblastní komory, na jejímž území vykonává povolání zubního lékaře. <br />
							Nemá-li uchazeč o zápis do ČSK zatím žádné pracoviště, bude mu přechodně Oblastní komora určena dle adresy pobytu na území ČR. <span className="color-red">Člen je v takovém případě povinen hned po zahájení výkonu povolání podat žádost o zápis do příslušné Oblastní komory, kde bude pracovat.</span></em>
					</p>
					<div className="row">
						<div className="col">
							<CheckBoxRadioFrame className="mb-3">
								<FormsRadio
									form={model.form}
									field="has_workplace"
									option={true}
									title="již mám poskytovatele a pracoviště, na kterém budu pracovat" />
								<FormsRadio
									form={model.form}
									field="has_workplace"
									option={false}
									title="zatím nemám žádného poskytovatele a pracoviště" />
							</CheckBoxRadioFrame>
						</div>
					</div>

					{model.form.getField("has_workplace").value &&
						<>
							{model.formsWorkplaces.get().length == 0 &&
								<Button onClick={model.formsWorkplaces.add} className="mb-5">
									<IconSvg name="plus" className="mr-3" /> {model.formsWorkplaces.get().length == 0 ? "Přidat poskytovatele a pracoviště" : "Přidat dalšího poskytovatele a pracoviště"}
								</Button>
							}

							{model.formsWorkplaces.get().map((formWorkplace, order) => {
								return (
									<div key={order}>
										<h2 className="d-flex align-items-center">
											<span>Poskytovatel zdravotní péče: {order > 0 ? order + 1 : ""}</span>
											{order > 1 &&
												<Button className="ml-3" onClick={_ => model.removeWorkplace(formWorkplace)}>Odebrat</Button>
											}
										</h2>

										<WebRequestsProvider order={order} form={formWorkplace} />

										{model.formsWorkplaces.get().length > 0 && order === model.formsWorkplaces.get().length - 1 &&
											<Button onClick={model.formsWorkplaces.add} className="mb-5">
												<IconSvg name="plus" className="mr-3" /> {model.formsWorkplaces.get().length == 0 ? "Přidat poskytovatele a pracoviště" : "Přidat dalšího poskytovatele a pracoviště"}
											</Button>
										}
									</div>
								);
							})}
						</>
					}

					<h2 >Členství v jiných profesních organizacích:</h2>
					<p>
						(profesní komory nebo odpovídající organizace v zahraničí)
					</p>
					<div className="row">
						<div className="col-md-6">
							<CheckBoxRadioFrame readOnly={formEntry.isReadOnly()} className="mb-5">
								<FormRadio form={formEntry} field="memebership_other" option="never" title="nejsem ani jsem nebyl/a nikdy členem jiné profesní organizace" />
								<FormRadio form={formEntry} field="memebership_other" option="now" title="jsem stále členem jiné profesní organizace" />
								<FormRadio form={formEntry} field="memebership_other" option="past" title="byl/a jsem členem jiné profesní organizace" />
							</CheckBoxRadioFrame>
						</div>
					</div>
					{formEntry.getField("memebership_other").value !== "never" &&
						<>
							<div className="row">
								<div className="col-md-6">
									<FormsInput form={formEntry} field="memebership_other_organization_name" />
								</div>
								{formEntry.getField("memebership_other").value === "past" &&
									<div className="col-md-6">
										<FormsInput form={formEntry} field="memebership_other_date_of_finish" inputProps={{ type: "date" }} />
									</div>
								}
								<div className="col-12">
									<FormFileUpload form={formEntry} field="memebership_other_attachment" />
								</div>
							</div>
						</>
					}

					{!props.restore ?
						<>
							<div className="mb-5">
								<h2>Kopie dokladů:</h2>

								{model.requireAttachmentDegree() &&
									<FormFiles
										forms={model.formsAttachmentDegree}
										required={false}
										label={<>Kopie diplomu včetně dodatku k diplomu nebo případně potvrzení o absolvování studia. Pokud je dokladem k žádosti „Potvrzení o absolvování studia“, musí člen Komory dodatečně doložit ověřenou kopii diplomu a dodatku k diplomu.</>}
									/>
								}

								{model.requireAttachmentProfessionPermit() &&
									<FormFileUpload form={formEntry} field="attachment_profession_permit" className="mb-4" />
								}

								{model.requireAttachmentNonCzechDegreeAndEducation() &&
									<>
										<FormFiles
											forms={model.formsAttachmentsNonCzechDegrees}
											required={true}
											label={
												<>
													Kopie dokladů o dosažené kvalifikaci (diplom, osvědčení, event. jiný doklad) vydaných
													příslušným orgánem nebo institucí členského státu, které jsou uvedeny v seznamu dokladů
													o dosažené kvalifikaci (§ 28a odst. 1 zákona č. 95/2004 Sb.) a jejich překlady do českého
													jazyka (musí být proveden překladatelem zapsaným do seznamu znalců a tlumočníků České republiky).
												</>
											}

										/>

										<FormFiles
											forms={model.formsAttachmentssNonCzechEducations}
											required={true}
											label={
												<>
													Kopie dokladů o absolvovaném vzdělávání (přílohy diplomů) a jejich překlady do českého
													jazyka (musí být proveden překladatelem zapsa- ným do znalců a tlumočníků České republiky)
													v případě, že doklad není uveden v seznamu dokladů o dosažené kvalifikaci
													(§ 28a odst. 1 zá- kona č. 95/2004 Sb.).
												</>
											}
										/>
										<FormFileUpload form={formEntry} field="attachment_non_czech_degree_confirmation" />
									</>
								}

								<FormFiles
									forms={model.formsAttachmentsMedicalCertificates}
									required={true}
									label={<>Kopie dokladu o zdravotní způsobilosti (<em>nesmí být starší 90 dnů</em>)</>}
									hint={
										<small>
											Zdravotní způsobilost se dokládá lékařským posudkem, který vydává registrující poskytovatel zdravotních
											služeb v oboru všeobecné praktické lékařství v ČR (náležitosti posudku - viz <a download href="/images/vzor-lekarsky-posudek-zdravotni-zpusobilost.pdf">Vzor lékařského posudku</a>),
											a není-li, jiný praktický lékař. V případě zahraničního posudku je třeba doložit překlad do českého
											jazyka (musí být provedeny překladatelem zapsaným do seznamu znalců a tlumočníků České republiky). Doklad nesmí být starší 90 dnů.
										</small>
									}
								/>

								<FormFiles
									forms={model.formsAttachmentsIntegrityConfirmations}
									required={true}
									label={<>Kopie dokladu o bezúhonnosti (<em>nesmí být starší 90 dnů</em>)</>}
									hint={
										<small>
											Bezúhonnost se dokládá výpisem z evidence Rejstříku trestů, dokladem prokazujícím splnění podmínky bezúhonnosti
											vydaným státem, jehož je fyzická osoba občanem, a doklady vydanými státy, ve kterých se fyzická osoba zdržovala
											v posledních 3 letech nepřetržitě déle než 6 měsíců; tyto doklady nesmí být starší 90 dnů. Nevydává-li stát
											uvedený ve větě první výpis z evidence trestů nebo rovnocenný doklad, nebo nelze-li jej získat, předloží fyzická
											osoba čestné prohlášení o bezúhonnosti, které učinila před notářem nebo příslušným orgánem tohoto státu. Cizinec,
											který je nebo byl státním občanem jiného členského státu Evropské unie nebo má nebo měl adresu bydliště v jiném
											členském státě Evropské unie, může místo výpisu z evidence obdobné rejstříku trestů doložit bezúhonnost výpisem
											z Rejstříku trestů s přílohou obsahující formace, které jsou zapsané v evidenci trestů jiného členského státu
											Evropské unie. V případě zahraničního výpisu je třeba doložit i překlad do českého jazyka (musí být provedeny
											překladatelem zapsaným do seznamu znalců a tlumočníků České republiky). Fyzické osoby, které mají zřízenu datovou
											schránku, mohou zaslat elektronický výpis z evidence Rejstříku trestů nebo doložit vytištěný elektronický výpis
											v konvertované podobě.
										</small>
									}
								/>

								{model.requireAttachmentResidencePermit() &&
									<FormFileUpload form={formEntry} field="attachment_residence_permit" />
								}
							</div>
						</>
						:
						<div className="mb-5">
							<h2 >Povinné kopie dokladů:</h2>

							<FormFiles
								forms={model.formsAttachmentsMedicalCertificates}
								required={true}
								label={<>Kopie dokladu o zdravotní způsobilosti</>}
								hint={
									<small>
										Zdravotní způsobilost se dokládá lékařským posudkem, který vydává registrující poskytovatel zdravotních služeb v oboru všeobecné praktické lékařství v ČR (náležitosti
										posudku - viz <a download href="/images/vzor-lekarsky-posudek-zdravotni-zpusobilost.pdf">Vzor lékařského posudku</a>), a není-li, jiný praktický lékař. V případě zahraničního posudku je třeba doložit překlad do českého jazyka (musí být provedeny
										překladatelem zapsaným do seznamu znalců a tlumočníků České republiky).
									</small>
								}
							/>

							<FormFiles
								forms={model.formsAttachmentsIntegrityConfirmations}
								required={true}
								label={<>Kopie dokladu o bezúhonnosti (nesmí být starší 90 dnů)</>}
								hint={<em>
									Bezúhonnost se dokládá výpisem z evidence Rejstříku trestů, dokladem prokazujícím splnění podmínky bezúhonnosti vydaným státem, jehož je fyzická osoba občanem, a doklady vydanými státy, ve kterých se fyzická osoba zdržovala v posledních 3 letech nepřetržitě déle než 6 měsíců; tyto doklady nesmí být starší 3 měsíců.
									Nevydává-li stát uvedený ve větě první výpis z evidence trestů nebo rovnocenný doklad, nebo nelze-li jej získat, předloží fyzická osoba čestné prohlášení o bezúhonnosti, které učinila před notářem nebo příslušným orgánem tohoto státu. Cizinec, který je nebo byl státním občanem jiného členského státu Evropské unie nebo má
									nebo měl adresu bydliště v jiném členském státě Evropské unie, může místo výpisu z evidence obdobné rejstříku trestů doložit bezúhonnost výpisem z Rejstříku trestů
									s přílohou obsahující formace, které jsou zapsané v evidenci trestů jiného členského státu Evropské unie. V případě zahraničního výpisu je třeba doložit i překlad do
									českého jazyka (musí být provedeny překladatelem zapsaným do seznamu znalců a tlumočníků České republiky). Fyzické osoby, které mají zřízenu datovou schránku,
									mohou zaslat elektronický výpis z evidence Rejstříku trestů nebo doložit vytištěný elektronický výpis v konvertované podobě.
								</em>}
							/>

							<FormFileUpload
								form={formEntry}
								field="attachment_residence_permit"
							/>


							<h2 >Doklady o případných provedených změnách <span className="text-danger">(nepovinné, pouze pokud jste provedl/a změnu)</span>:</h2>

							<hr />

							<h2 className="mb-5">Změna jména / příjmení:</h2>

							<FormFileUpload
								form={formEntry}
								field="new_attachment"
							/>

							<hr />
							<>

								<h2 className="mb-5">Titul – změna / přidání:</h2>

								<p>
									<strong>Poučení:</strong> <br />
									<em>
										Změna titulu je třeba doložit kopií dokladu o dosažení tohoto titulu (diplom apod.). Pokud je titul získán v zahraničí, je třeba doložit i jeho uznání Rozhodnutím Ministerstva zdravotnictví ČR. V případě zahraničního dokladu (diplomu) je třeba doložit i překlad do českého jazyka (musí být proveden překladatelem zapsaným do seznamu znalců a tlumočníků České republiky). Překlad není nutný u diplomů v latině (vydaných v ČR) a u diplomů ve slovenštině.
									</em>
								</p>

								<p className="mb-5">Jako doklad k žádosti přikládám:</p>

								<FormFileUpload
									form={formEntry}
									field="attachment_diploma"
								/>
								<FormFileUpload
									form={formEntry}

									field="attachment_mzcr"
								/>
								<FormFileUpload
									form={formEntry}
									field="attachment_czech_diploma"
								/>

							</>
							<>
								<hr />

								<h2 className="mb-5">Přidělení rodného čísla:</h2>

								<FormsInput
									form={formEntry}
									field="pin"
								/>

								<p className="mb-5">
									<strong>Poučení:</strong> <br />
									<em>Přidělení rodného čísla je třeba doložit kopií dokladu, kde je nové rodné číslo uvedeno (občanský průkaz apod.).</em>
								</p>

								<FormFileUpload
									form={formEntry}
									field="attachment_pin"
								/>
							</>
							<hr />

							<h2 className="mb-5">Uznání vzdělání / složení aprobační zkoušky:</h2>

							<FormsInput
								form={formEntry}
								field="exam_date"
								inputProps={{
									type: "date"
								}}
							/>

							<p className="mb-5">
								<strong>Poučení:</strong> <br />

								<em>Změnu je třeba doložit Rozhodnutím Ministerstva zdravotnictví ČR o uznání způsobilosti k výkonu povolání zubního lékaře v ČR.</em>
							</p>


							<FormFileUpload
								form={formEntry}
								field="attachment_exam"
							/>

							<hr />

							<h2 className="mb-5">Získání / uznání dalšího vzdělání:</h2>

							<CheckBoxRadioFrame className="mb-5 py-4">
								<strong className="ml-4">Specializace (dle zákona č. 95/2004 Sb.):</strong>

								<FormCheckBox
									form={formEntry}
									field="new_education_ortodency"
								/>
								<FormCheckBox
									form={formEntry}
									field="new_education_oral_maxifacial_chirurgy"
								/>
								<FormCheckBox
									form={formEntry}
									field="new_education_clinical_stomatology"
								/>

								<strong className="ml-4">Další dosažené vzdělání:</strong>

								<FormCheckBox
									form={formEntry}
									field="new_education_atestation_i"
								/>

								<FormCheckBox
									form={formEntry}
									field="new_education_atestation_ii"
								/>


								<strong className="ml-4">Nástavbová specializace:</strong>

								<FormCheckBox
									form={formEntry}
									field="new_education_stomatological_chirurgy"
								/>
								<FormCheckBox
									form={formEntry}
									field="new_education_children_stomatology"
								/>
								<FormCheckBox
									form={formEntry}
									field="new_education_paradontology"
								/>
								<FormCheckBox
									form={formEntry}
									field="new_education_social_dentists"
								/>
								<FormCheckBox
									form={formEntry}
									field="next_education_ext_prosthetics"
								/>
								<FormCheckBox
									form={formEntry}
									field="new_education_other"
								/>
							</CheckBoxRadioFrame>

							{model.form.getField("new_education_other").value &&
								<FormsInput
									form={formEntry}
									field="new_education_other_specific"
								/>
							}

							<p className="mb-5">
								<strong>Poučení:</strong> <br />
								<em>
									Získání specializace nebo atestace je třeba doložit kopií dokladu o dosažení specializace nebo atestace (diplom apod.). Pokud je specializace nebo atestace získána
									v zahraničí, je třeba doložit i její uznání Rozhodnutím Ministerstva zdravotnictví ČR a úředním překladem dokladu o dosažení do češtiny (tj. provedený překladatelem
									nebo tlumočníkem zapsaným do seznamu znalců a tlumočníků ČR).
								</em>
							</p>

							<FormFileUpload
								form={formEntry}
								field="attachment_education_diploma"
							/>

							<FormFileUpload
								form={formEntry}
								field="attachment_education_mzcr"
							/>

							<FormFileUpload
								form={formEntry}
								field="attachment_education_czech"
							/>

							<hr />

							<h2 className="mb-5">
								Prodloužení povolení k pobytu:
							</h2>


							<FormsInput
								form={formEntry}
								field="stay_extend"
							/>

							<p className="mb-5">
								<strong>Poučení:</strong> <br />
								<em>
									Prodloužení povolení k pobytu na území ČR je třeba doložit příslušným dokladem (např. modrá karta, záznam v cestovním pasu, vízum apod.).
								</em>
							</p>

							<FormFileUpload
								form={formEntry}
								field="attachment_stay_extend"
							/>

							<hr />

							<h2 className="mb-5">Změna státní příslušnosti:</h2>

							<FormsInput
								form={formEntry}
								field="new_nationality"
							/>

							<p className="mb-5">
								<strong>Poučení:</strong> <br />
								<em>
									Státní příslušnost je třeba doložit příslušným dokladem (např. občanský průkaz, cestovní pas apod.).
								</em>
							</p>

							<FormFileUpload
								form={formEntry}
								field="attachment_new_nationality"
							/>

						</div>
					}


					<div className="mb-5">
						<h2 >Prohlášení: <span className="form-item__required">*</span></h2>
						<FormCheckBox form={formEntry} field="declaration_legal_capacity" />
						<FormCheckBox form={formEntry} field="declaration_of_truth" />
						<FormCheckBox form={formEntry} field="pin_aggreement" />
					</div>


					{!props.id &&
						<>
							<div className="text-left">
								<em className="web-request-osk-csk-entry-page-text">Beru na vědomí, že tato žádost je zpoplatněna dle aktuálního usnesení sněmu ČSK ve výši 1500 Kč (registrační poplatek).</em>
							</div>
							<div className="text-right">
								<div className="mb-2"><em><span className="form-item__required">*</span> Údaje označené hvězdičkou jsou povinné.</em></div>
								<em>Odesláním potvrzuji správnost údajů.</em><br /><br />
								<Button theme="primary" onClick={_ => model.confirm(props.restore)} spinner={model.common.justSending()}>Odeslat</Button>
							</div>
						</>
					}
				</>
			}
		</WebRequest >
	);
}

interface FormFilesProps {
	forms: forms.FormCollection<webRequestOskCskEntry3.FilesFormFields>;
	label: React.ReactNode;
	hint?: React.ReactNode;
	required?: boolean;
}

function FormFiles(props: FormFilesProps) {
	return (
		<div className="web-request-osk-csk-entry-page__files mb-4">
			<div className="mb-1">
				<div>
					{props.label}
					{props.required &&
						<span className="form-item__required ml-1">*</span>
					}
				</div>
				<div>
					{props.hint}
				</div>
			</div>
			{
				props.forms.get().map((form, index) =>
					<FormFileUpload
						key={index}
						form={form}
						field="file"
					/>
				)
			}
			<Button className="mb-3" onClick={props.forms.add}>
				<IconSvg name="plus" className="mr-3" />

				{props.forms.get().length === 0 &&
					<>Připojit soubor</>
				}
				{props.forms.get().length > 0 &&
					<>Připojit další soubor</>
				}
			</Button >
		</div>
	);
}

export default state.bindContainers(
	WebRequestOskCskEntryPage3,
	c => c.webRequestOskCskEntry3
);