import * as React from "react";

export interface TimeLineProps<Item> {
	items: Item[];
	mark: (item: Item) => React.ReactNode;
	content: (item: Item) => React.ReactNode;
	range?: (item: Item) => React.ReactNode;
	stages?: boolean;
}

export default function TimeLine<Item>(props: TimeLineProps<Item>) {
	return (
		<div className="time-line">
			<div className="time-line__line">
			</div>
			<div className="w-100">
				{props.items.map((i, index) =>
					<div key={index}>
						<div className="time-line__mark">
							{props.mark(i)}
							{props.range &&
								<div className="font-weight-normal">{props.range(i)}</div>
							}
						</div>
						<div>
							{props.content(i)}
						</div>
					</div>)}
			</div>
		</div>
	);
}