/**
 * Model žádosti o registraci (nebo změnu) v hlavní OSK
 */

import * as context from "../../../context";
import * as state from "../../../lib/state";
import * as forms from "../../../lib/forms";
import * as api from "../../../lib/api";
import * as common from "../../../lib/common";
import * as webRequest from "../../shared/web-request/web-request";
import * as apiFile from "../../system/file/api-file";
import * as apiMembers from "../../shared/members/api-members";
import * as apiWorkplace from "../../shared/workplace/api-workplace";
import * as validations from "../../../lib/validations";
import * as formatting from "../../../lib/formatting";

interface State {
	justLoadingHostChambers: boolean;
	loaded: boolean;
}

export interface FileFormFields {
	attachment?: api.CodeBookItem;
}

interface FormData extends FormFields {
	attachments: FileFormFields[];
}

interface HostChamber extends apiMembers.VisitingRegionalChamber {
	checked: boolean;
}

/**
 * Definice dat formuláře
 */
export interface FormFields {
	primary_chamber_old?: api.CodeBookItem;
	primary_chamber_new?: api.CodeBookItem;
	workplace?: apiWorkplace.Workplace;
	workplace_new_name: string;
	workplace_new_provider: string;
	workplace_new_provider_address_street: string;
	workplace_new_provider_address_city: string;
	workplace_new_provider_address_postcode: string;

	workplace_new_address_street: string;
	workplace_new_address_city: string;
	workplace_new_address_postcode: string;
	workplace_new_contact_phone: string;
	workplace_new_contact_email: string;
	workplace_new_ico: string;
	certificate_file?: apiFile.File;
	certificate_doctor_name: string;
	certificate_name: string;
	date: string;
	relationship_legal_person: boolean;
	relationship_statutory_authority: boolean;
	relationship_osvc: boolean;
	relationship_employee: boolean;
	relationship_professional_representative: boolean;
	relationship_chief_physician: boolean;
	relationship_primary: boolean;
	host_chambers: HostChamber[];
	current_regional_chamber: "set_as_cancel" | "set_as_secondary" | "set_as_only_emergency";
	request_workplace_type?: "existing_workplace" | "hosting_workplace" | "not_found";
	date_of_entry_into_force: string;
	date_of_validity_of_the_amendments: string;
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	private stateContainer: state.StateContainer<State>;
	public form: forms.Form<FormFields>;
	public common: webRequest.Model;
	public formsFiles: forms.FormCollection<FileFormFields>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({ justLoadingHostChambers: false, loaded: false }, context);
		this.common = new webRequest.Model(context);
		this.form = new forms.Form<FormFields>({
			fields: {
				primary_chamber_old: {
					title: "Jsem zapsán/a v seznamu členů Oblastní komory (hlavní)",
					defaultValue: undefined
				},
				date_of_entry_into_force: {
					title: "Datum nabytí právní moci Rozhodnutí",
					validate: (value, field, form) => form.getField("request_workplace_type").value == "not_found" && !value ? "Datum je povinné pole" : "",
					defaultValue: ""
				},
				date_of_validity_of_the_amendments: {
					title: "Datum platnosti změn uvedené v Rozhodnutí (je-li uvedeno)",
					defaultValue: ""
				},
				current_regional_chamber: {
					title: "",
					defaultValue: "set_as_cancel"
				},
				workplace: {
					title: "Vyberte poskytovatele zdravotní péče a pracoviště (nově ohlašované - dle IČO, názvu, adresy).",
					onChange: (value) => this.form.setField("primary_chamber_new", value == null ? undefined : value.regional_chamber!),
					defaultValue: undefined,
					required: true,
					validate: (value, field, form) => form.getField("request_workplace_type").value == "existing_workplace" && !value ? "Poskytovatel je povinné pole" : ""
				},
				workplace_new_provider: {
					title: "Název poskytovatele",
					defaultValue: "",
					required: (form) => form.getField("request_workplace_type").value == "not_found",
					validate: (value, field, form) => form.getField("request_workplace_type").value == "not_found" && !value ? "Název je povinné pole" : ""
				},
				workplace_new_provider_address_street: {
					title: "Ulice a číslo (sídlo poskytovatele)",
					defaultValue: "",
					required: (form) => form.getField("request_workplace_type").value == "not_found",
					validate: (value, field, form) => form.getField("request_workplace_type").value == "not_found" && !value ? "Ulice a číslo je povinné pole" : ""
				},
				workplace_new_provider_address_city: {
					title: "Obec (sídlo poskytovatele)",
					defaultValue: "",
					required: (form) => form.getField("request_workplace_type").value == "not_found",
					validate: (value, field, form) => form.getField("request_workplace_type").value == "not_found" && !value ? "Obec je povinné pole" : ""
				},
				workplace_new_provider_address_postcode: {
					title: "PSČ (sídlo poskytovatele)",
					defaultValue: "",
					required: (form) => form.getField("request_workplace_type").value == "not_found",
					validate: (value, field, form) => form.getField("request_workplace_type").value == "not_found" && !value ? "PSČ je povinné pole" : value.trim().length == 0 ? "" : value.trim().length == 5 ? "" : form.getField("request_workplace_type").value == "not_found" ? "PSČ musí obsahovat 5 znaků" : ""
				},
				workplace_new_name: {
					title: "Název pracoviště",
					defaultValue: "",
					required: (form) => form.getField("request_workplace_type").value == "not_found",
					validate: (value, field, form) => form.getField("request_workplace_type").value == "not_found" && !value ? "Název je povinné pole" : ""
				},
				workplace_new_address_street: {
					title: "Ulice a číslo (pracoviště)",
					defaultValue: "",
					required: (form) => form.getField("request_workplace_type").value == "not_found",
					validate: (value, field, form) => form.getField("request_workplace_type").value == "not_found" && !value ? "Ulice a číslo je povinné pole" : ""
				},
				workplace_new_address_city: {
					title: "Obec (pracoviště)",
					defaultValue: "",
					required: (form) => form.getField("request_workplace_type").value == "not_found",
					validate: (value, field, form) => form.getField("request_workplace_type").value == "not_found" && !value ? "Obec je povinné pole" : ""
				},
				workplace_new_address_postcode: {
					title: "PSČ (pracoviště)",
					defaultValue: "",
					onChange: this.handleChamberByPostcode,
					required: (form) => form.getField("request_workplace_type").value == "not_found",
					validate: (value, field, form) => form.getField("request_workplace_type").value == "not_found" && !value ? "PSČ je povinné pole" : value.trim().length == 0 ? "" : value.trim().length == 5 ? "" : "PSČ musí obsahovat 5 znaků"
				},
				workplace_new_contact_phone: {
					title: "Telefon (pracoviště)",
					placeHolderText: "+420 XXX XXX XXX",
					defaultValue: "",
					validate: value => !validations.isPhoneNumber(value) && value.length > 0 ? "Telefon je v chybném formát" : ""
				},
				workplace_new_contact_email: {
					title: "E-mail (pracoviště)",
					defaultValue: ""
				},
				workplace_new_ico: {
					title: "IČO",
					defaultValue: "",
					required: (form) => form.getField("request_workplace_type").value == "not_found",
					validate: (value, field, form) => form.getField("request_workplace_type").value == "not_found" && !value ? "IČO je povinné pole" : ""
				},
				certificate_doctor_name: {
					title: "Lékař",
					defaultValue: ""
				},
				certificate_name: {
					title: "Název rozhodnutí",
					defaultValue: ""
				},
				date: {
					title: "ke dni",
					defaultValue: "",
					required: true,
					validate: value => !value ? "Ke dni je povinné pole" : formatting.getDateBefore(new Date(), -15) > new Date(value) ? "Datum nesmí být menší než 14 dní" : ""
				},
				primary_chamber_new: {
					title: "",
					readOnly: (form) => form.getField("request_workplace_type").value == "not_found" ? false : true,
					defaultValue: undefined
				},
				relationship_legal_person: {
					title: "Společník nebo člen právnické osoby poskytovalele",
					defaultValue: false
				},
				relationship_statutory_authority: {
					title: "Statutární orgán nebo člen statut. orgánu (např. jednatel)",
					defaultValue: false
				},
				relationship_osvc: {
					title: "OSVČ",
					defaultValue: false
				},
				relationship_employee: {
					title: "Zaměstnanec",
					defaultValue: false
				},
				relationship_professional_representative: {
					title: "Odborný zástupce",
					defaultValue: false
				},
				relationship_chief_physician: {
					title: "Vedoucí lékař",
					defaultValue: false
				},
				relationship_primary: {
					title: "Primář",
					defaultValue: false
				},
				host_chambers: {
					title: "",
					defaultValue: [],
				},
				request_workplace_type: {
					title: "",
					defaultValue: "existing_workplace"
				}
			}
		}, context, this.form);
		this.formsFiles = new forms.FormCollection({
			fields: {
				attachment: {
					title: "Připojit soubor",
					defaultValue: undefined,
					required: (form) => this.form.getField("request_workplace_type").value == "not_found",
					validate: (value, field, form) => this.form.getField("request_workplace_type").value == "not_found" && !value ? "Soubor je povinné pole" : ""
				}
			}
		}, this.context, this.form);
		this.formsFiles.add();
	}

	handleChamberByPostcode = async (value: string) => {
		if (value.length == 5) {
			const chamber = await this.context.webRequests.getRegionalChamberByPostcode(value) as api.CodeBookItem;
			this.form.setField("primary_chamber_new", chamber);
		}
	}

	addFile = () => this.formsFiles.add();

	removeFile = async (formFile: forms.Form<FileFormFields>) =>
		await this.context.standardDialogs.openYesNoDialog("Odstranit soubor?") === "yes"
			? this.formsFiles.remove(formFile)
			: undefined

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			...this.common.getStateContainers(),
			...this.form.getStateContainers()
		];
	}

	private createHostChamber(hostChamber: HostChamber) {
		return hostChamber;
	}

	toggleHostChamber = async (chamber: HostChamber) => {
		await this.form.setField(
			"host_chambers",
			this.form.getField("host_chambers").value.map(i => ({
				...i,
				checked: chamber.id === i.id ? !i.checked : i.checked
			})));
	}

	justLoadingHostChambers = () => {
		return this.stateContainer.get().justLoadingHostChambers;
	}

	loaded = () => {
		return this.stateContainer.get().loaded;
	}

	loadRegionalChambers = async () => {
		const loadedChambers = await common.withIndication({
			start: () => this.stateContainer.merge(_ => ({ justLoadingHostChambers: true })),
			exec: () => this.context.apiMembers.loadVisitingRegionalChambersWithFilter(false),
			finish: () => this.stateContainer.merge(_ => ({ justLoadingHostChambers: false, loaded: true }))
		});
		const hostChambers = loadedChambers.data.map(i => this.createHostChamber({ ...i, checked: false }));
		await this.form.setField("host_chambers", hostChambers);
	}

	loadPrimaryChamber = async (data?: any) => {
		let userProfile = this.context.authorization.getUserProfile();
		if (!userProfile) {
			userProfile = await this.context.authorization.loadUserProfile();
		}
		if (userProfile.profile.regional_chamber_main && !data) {
			await this.form.setField("primary_chamber_old", {
				id: userProfile.profile.regional_chamber_main.id,
				name: userProfile.profile.regional_chamber_main.name
			});
		}
		if (data && data.primary_chamber_old) {
			await this.form.setField("primary_chamber_old", {
				id: data.primary_chamber_old.id,
				name: data.primary_chamber_old.name
			});
		}
	}

	loadWorkplaces = async (prefix: string): Promise<apiWorkplace.Workplace[]> => {
		const workplaces = await this.context.apiWorkplace.standard.loadList({
			filter: prefix.length > 0
				? [
					api.qpOr([
						api.qp("name", "=", "*" + prefix + "*"),
						api.qp("provider.registration_number" as any, "=", prefix),
						api.qp("address.street" as any, "=", prefix + "*"),
						api.qp("address.city" as any, "=", prefix + "*")
					])
				]
				: []
		});
		return workplaces.data;
	}

	/**
	 * Načte a vrátí seznam oblastních komor pro našeptávač nové oblastní komory
	 */
	loadChambers = async (prefix: string): Promise<api.CodeBookItem[]> => {
		const chambers = await this.context.apiRegionalChambers.standard.loadList({
			filter: prefix.length > 0 ? [api.qp("name", "=", "*" + prefix + "*")] : []
		});
		return chambers.data.map(i => ({ id: i.id, name: i.name }));
	}

	/**
	 * Potvrdí formulář uživatelem
	 */
	confirm = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const data: FormData = {
				...this.form.getFields(),
				attachments: this.formsFiles.get().filter(i => i.getField("attachment").value !== undefined).map(i => i.getFields())
			};
			await this.common.send(data, "zadost-o-zapis-do-hlavni-osk");
		}
		else {
			await this.context.standardDialogs.openInformationDialog("Proběhla kontrola formuláře. Opravte prosím označené chyby a akci opakujte.");
		}
	}

	/**
	 * Načte data do formuláře 
	 */
	loadData = async (webRequestId: string) => {
		await this.common.loadData(webRequestId);
		const webRequest = this.common.getWebRequest();
		if (webRequest) {
			const data = webRequest.data as FormData;
			const { attachments, ...formFields } = data;
			await Promise.all([
				this.form.setFields(formFields),
				this.formsFiles.setFields(attachments),
				this.loadPrimaryChamber(data),
				this.loadRegionalChambers(),
			]);
		}
	}

	/**
	 * Provede reset formuláře
	 */
	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
			this.common.reset(),
			this.loadPrimaryChamber(),
			this.loadRegionalChambers(),
		]);
	}
}