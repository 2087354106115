/**
 * Žádost o zápis do ČSK a OSK
 */

import * as context from "../../../context";
import * as state from "../../../lib/state";
import * as forms from "../../../lib/forms";
import * as formatting from "../../../lib/formatting";
import * as validations from "../../../lib/validations";
import * as webRequest from "../../shared/web-request/web-request";
import * as api from "../../../lib/api";
import * as apiFile from "../../system/file/api-file";
import * as apiWorkplace from "../../shared/workplace/api-workplace";
import * as apiCountry from "../../shared/country/api-country";

const CZECH_NATIONALITY_CODE = "CZ";

/**
 * Varianta zobrazených příloh podle zadání. Podmínky jsou definovány v metodě getAttachmentsVariant()
 */
type AttachmentsVariant = 1 | 2 | 3 | 4;

/**
 * Stav modulu
 */
export interface State {
	countries: apiCountry.Country[];
	titles_before: api.CodeBookItem[];
	titles_after: api.CodeBookItem[];
	pedagogical_titles: api.CodeBookItem[];
	originalFields: FormFields;
}

/**
 * Definice polí hlavního fomuláře
 */
export interface FormFields {
	has_workplace: boolean;
	certificated_in: "cr" | "out-of-cr";
	membership_eu: "eu" | "out-of-eu";
	loaded: boolean;
	// Identifikační údaje
	title_before?: api.CodeBookItem;
	first_name: string;
	last_name: string;
	middle_name: string;
	birth_name: string;
	title_after?: api.CodeBookItem;
	edu_title?: api.CodeBookItem;
	phone: string;
	email: string;
	rc: string;
	birth_date: string;
	gender?: "male" | "female";
	nationality?: apiCountry.Country;
	validity_of_stay: string;

	// Dosažené vzdělání
	university_name: string;
	graduated_date: string;
	graduated_diploma_number: string;
	university_custom: string;


	// Specializace
	specialization_ortodoncy: boolean;
	specialization_clinic_stomatology: boolean;
	specialization_surgery: boolean;

	// Další dosažené vzdělání
	next_education_atest_1: boolean;
	next_education_atest_2: boolean;
	next_education_ext_surgery: boolean;
	next_education_ext_children_stoma: boolean;
	next_education_ext_parodontology: boolean;
	next_education_ext_social_medicine: boolean;
	next_education_ext_prosthetics: boolean;
	next_education_other: string;

	// členství v jiných profesních organizacích
	memebership_other: "now" | "past" | "never";
	memebership_other_organization_name: string;
	memebership_other_date_of_finish: string;
	memebership_other_attachment?: apiFile.File;

	// Žádost o zařazení do vstupního vzdělávacího programu
	include_to_entry_education_program: boolean;

	// Povinné kopie dokladů
	attachment_profession_permit?: apiFile.File;
	attachment_non_czech_degree_confirmation?: apiFile.File;
	attachment_residence_permit?: apiFile.File;

	// Prohlášení a souhlasy
	declaration_legal_capacity: boolean;
	declaration_of_truth: boolean;
	pin_aggreement: boolean;


	// Dále Maxovy nekomentované fieldy pro variantu formuláře č.3
	new_attachment?: apiFile.File;

	attachment_diploma?: apiFile.File;
	attachment_mzcr?: apiFile.File;
	attachment_czech_diploma?: apiFile.File;

	pin: string;
	attachment_pin?: apiFile.File;

	exam_date: string;
	attachment_exam?: apiFile.File;

	new_education_ortodency: boolean;
	new_education_oral_maxifacial_chirurgy: boolean;
	new_education_clinical_stomatology: boolean;
	new_education_atestation_i: boolean;
	new_education_atestation_ii: boolean;
	new_education_stomatological_chirurgy: boolean;
	new_education_children_stomatology: boolean;
	new_education_paradontology: boolean;
	new_education_social_dentists: boolean;
	new_education_stomatology_prostetics: boolean;
	new_education_other: boolean;
	new_education_other_specific: string;

	attachment_education_diploma?: apiFile.File;
	attachment_education_mzcr?: apiFile.File;
	attachment_education_czech?: apiFile.File;

	stay_extend: string;
	attachment_stay_extend?: apiFile.File;

	new_nationality: string;
	attachment_new_nationality?: apiFile.File;

	doctor_or_absolvent: "doctor" | "absolvent";
	visiting_only_emergency_regional_chambers: boolean;
}



function createFormOptions(model: Model): forms.FormOptions<FormFields> {
	return {
		fields: {
			certificated_in: {
				title: "V jakém jazyce byla získána kvalifikace",
				defaultValue: "cr"
			},
			has_workplace: {
				defaultValue: true,
				title: ""
			},
			visiting_only_emergency_regional_chambers: {
				title: "Hostující oblasti - jen pohotovost",
				defaultValue: false
			},
			membership_eu: {
				title: "Státní příslušenství v EU",
				defaultValue: "eu"
			},
			title_before: {
				title: "Titul před",
				defaultValue: undefined
			},
			first_name: {
				title: "Jméno",
				defaultValue: "",
				required: true,
				validate: value => value.trim().length === 0 ? "Zadejte prosím jméno" : ""
			},
			last_name: {
				title: "Příjmení",
				defaultValue: "",
				required: true,
				validate: value => value.trim().length === 0 ? "Zadejte prosím příjmení" : ""
			},
			middle_name: {
				title: "Prostřední jméno",
				defaultValue: ""
			},
			birth_name: {
				title: "Rodné příjmení",
				defaultValue: ""
			},
			title_after: {
				title: "Titul za",
				defaultValue: undefined
			},
			edu_title: {
				title: "Pedagogický titul",
				defaultValue: undefined
			},
			phone: {
				title: "Telefon",
				placeHolderText: "+420 XXX XXX XXX",
				defaultValue: "",
				validate: value => validations.validatePhoneNumber(value)
			},
			email: {
				title: "E-mail",
				defaultValue: "",
				required: true,
				validate: value => validations.validateEmailAddress(value, true)
			},
			rc: {
				title: "Rodné číslo (cizí státní příslušníci s dosud nepřiděleným rodným číslem ponechají za lomítkem nuly)",
				defaultValue: "",
				placeHolderText: "000000/0000",
				required: true,
				validate: value => value.trim().length === 0 ? "Zadejte prosím rodné číslo" : ""
			},
			birth_date: {
				title: "Datum narození",
				defaultValue: "",
				required: true,
				validate: value => {
					if (!validations.isValidDate(value)) {
						return "Chybný formát data narození (zkontrolujte formát DEN. MĚSÍC. ROK)";
					}

					const birthdDate = formatting.parseDate(value);
					const now = new Date();
					if (birthdDate && now < birthdDate) {
						return "Datum narození je příliš vysoké.";
					}

					if (birthdDate && birthdDate.getFullYear() < 1900) {
						return "Datum narození je příliš málé.";
					}

					return "";
				},
				onChange: (value, field, form) => model.setDefaultRegistrationNumber(formatting.parseDate(value)!, form.getField("gender").value == "female" ? 1 : 2)
			},
			gender: {
				title: "Pohlaví",
				defaultValue: "female",
				onChange: (value, field, form) => model.setDefaultRegistrationNumber(formatting.parseDate(form.getField("birth_date").value)!, value == "female" ? 1 : 2)
			},
			nationality: {
				title: "Státní příslušnost",
				defaultValue: undefined,
				required: true,
				validate: value => value === undefined ? "Vyberte prosím státní příslušnost" : ""
			},
			validity_of_stay: {
				title: "Povolení k pobytu v ČR platné do",
				defaultValue: "",
				validate: value => validations.validateDate(value)
			},
			university_name: {
				title: "Název školy",
				defaultValue: "",
				required: true,
				validate: value => value.trim().length === 0 ? "Zadejte prosím název školy" : ""

			},
			university_custom: {
				title: "Název jiné školy",
				defaultValue: ""
			},
			graduated_date: {
				title: "Datum ukončení studia",
				defaultValue: "",
				required: true,
				validate: value => validations.validateDate(value, true)
			},
			graduated_diploma_number: {
				title: "Číslo diplomu (Pokud ještě nemáte diplom, nevyplňujte. Diplom a dodatky k diplomu doložte ihned po promoci.)",
				defaultValue: ""
			},
			specialization_ortodoncy: {
				title: "ortodoncie",
				defaultValue: false
			},
			specialization_clinic_stomatology: {
				title: "klinická stomatologie",
				defaultValue: false
			},
			specialization_surgery: {
				title: "orální a maxilofaciální chirurgie",
				defaultValue: false
			},
			next_education_atest_1: {
				title: "atestace I. stupně",
				defaultValue: false
			},
			next_education_atest_2: {
				title: "atestace II. stupně",
				defaultValue: false
			},
			next_education_ext_surgery: {
				title: "nástavbová specializace stomatologická chirurgie",
				defaultValue: false
			},
			next_education_ext_children_stoma: {
				title: "nástavbová specializace dětská stomatologie",
				defaultValue: false
			},
			next_education_ext_parodontology: {
				title: "nástavbová specializace parodontologie",
				defaultValue: false
			},
			next_education_ext_social_medicine: {
				title: "nástavbová specializace sociální lékařství a organizace zdravotnictví",
				defaultValue: false
			},
			next_education_ext_prosthetics: {
				title: "nástavbová specializace stomatologická protetika",
				defaultValue: false
			},
			next_education_other: {
				title: "Jiné vzdělání (uveďte jaké)",
				defaultValue: ""
			},
			memebership_other: {
				title: "nejsem ani jsem nebyl nikdy členem jiné profesní organizace",
				defaultValue: "never"
			},
			memebership_other_organization_name: {
				title: "Název organizace",
				defaultValue: "",
				required: true,
				validate: (value, field, form) => {
					const membership = form.getField("memebership_other").value;
					switch (membership) {
						case "now":
						case "past": return value.trim().length === 0 ? "Zadejte prosím název organizace" : "";
						default: return "";
					}
				}
			},
			memebership_other_date_of_finish: {
				title: "Datum ukončení členství",
				defaultValue: "",
				required: true,
				validate: (value, field, form) => {
					const membership = form.getField("memebership_other").value;
					if (membership === "past") {
						return validations.validateDate(value, true);
					}
					return "";
				}
			},
			memebership_other_attachment: {
				title: "Doložte, prosím, kopii potvrzení o členství a bezúhonnosti v zahraniční/ch profesní/ch organizaci/ích (např. Good standing letter apod.) a jeho/jejich překlad/y do českého jazyka(musí být proveden překladatelem zapsaným do seznamu znalců a tlumočníků České republiky.",
				defaultValue: undefined,
				required: true,
				validate: (value, field, form) => {
					const membership = form.getField("memebership_other").value;
					switch (membership) {
						case "now":
						case "past": return value === undefined ? "Nahrajte prosím prosím kopii potvrzení o členství a bezúhonnosti" : "";
						default: return "";
					}
				}
			},
			include_to_entry_education_program: {
				title: "Zařažení do vstupního vzdělávacího programu",
				defaultValue: false
			},
			attachment_profession_permit: {
				title: "Kopie Rozhodnutí Ministerstva zdravotnictví k povolení výkonu zdravotnického povolání zubního lékaře",
				defaultValue: undefined,
				required: true,
				validate: value => model.requireAttachmentProfessionPermit() && !value ? "Nahrajte prosím povinnou přílohu" : ""
			},
			attachment_non_czech_degree_confirmation: {
				title: "Kopie osvědčení o uznání zahraničního vysokoškolského vzdělání a kvalifikace v České republice (tzv.nostrifikace) v případě, že doklad o dosažené kvalifikaci není uveden v seznamu dokladů o dosažené kvalifikaci(§ 28a odst. 1 zákona č. 95/2004 Sb.)",
				defaultValue: undefined,
				required: true,
				validate: value => model.requireAttachmentNonCzechDegreeAndEducation() && !value ? "Nahrajte prosím povinnou přílohu" : ""
			},
			attachment_residence_permit: {
				title: "Kopie průkazu o povolení k pobytu",
				defaultValue: undefined,
				required: true,
				validate: value => model.requireAttachmentResidencePermit() && !value ? "Nahrajte prosím povinnou přílohu" : ""
			},
			declaration_legal_capacity: {
				title: "Prohlašuji, že jsem plně svéprávný.",
				defaultValue: false,
				validate: value => !value ? "Potvrďte prosím prohlášení o svéprávnosti." : ""
			},
			declaration_of_truth: {
				title: "Prohlašuji, že všechny údaje uvedené v této žádosti a ve všech jejích přílohách jsou správné, úplné a pravdivé.",
				defaultValue: false,
				validate: value => !value ? "Potvrďte prosím prohlášení o správnosti, úplnosti a pravdivosti údajů." : ""
			},
			pin_aggreement: {
				title: "Souhlasím s tím, aby Česká stomatologická komora využívala rodné číslo, jehož jsem nositelem, výhradně pro vnitřní potřebu při vedení členské evidence i v dalších registrech ČSK.",
				defaultValue: false,
				validate: value => !value ? "Potvrďte prosím souhlas s využitím rodného čísla." : ""
			},
			new_attachment: {
				title: "Jako doklad k žádosti přikládám:",
				defaultValue: undefined
			},

			attachment_diploma: {
				title: "Diplom",
				defaultValue: undefined,
			},
			attachment_mzcr: {
				title: "Rozhodnutí MZČR",
				defaultValue: undefined
			},
			attachment_czech_diploma: {
				title: "Úřední překlad diplomu do češtiny",
				defaultValue: undefined
			},
			pin: {
				title: "Rodné číslo",
				defaultValue: ""
			},
			attachment_pin: {
				title: "Občanský průkaz, jiné",
				defaultValue: undefined
			},

			exam_date: {
				title: "Prohlašuji, že jsem složil/a aprobační zkoušku",
				defaultValue: ""
			},
			attachment_exam: {
				title: "Rozhodnutí MZČR",
				defaultValue: undefined
			},

			new_education_ortodency: {
				title: "ortodoncie",
				defaultValue: false
			},
			new_education_oral_maxifacial_chirurgy: {
				title: "orální a maxilofaciální chirurgie",
				defaultValue: false
			},
			new_education_clinical_stomatology: {
				title: "klinická stomatologie",
				defaultValue: false
			},
			new_education_atestation_i: {
				title: "atestace I. stupně",
				defaultValue: false
			},
			new_education_atestation_ii: {
				title: "atestace II. stupně",
				defaultValue: false
			},
			new_education_stomatological_chirurgy: {
				title: "stomatologická chirurgie",
				defaultValue: false
			},
			new_education_children_stomatology: {
				title: "dětská stomatologie",
				defaultValue: false
			},
			new_education_paradontology: {
				title: "parodontologie",
				defaultValue: false
			},
			new_education_social_dentists: {
				title: "sociální lékařství a organizace zdravotnictví",
				defaultValue: false
			},
			new_education_stomatology_prostetics: {
				title: "stomatologická protetika",
				defaultValue: false
			},
			new_education_other: {
				title: "jiné",
				defaultValue: false
			},
			new_education_other_specific: {
				title: "Uveďte jaké",
				defaultValue: ""
			},

			attachment_education_diploma: {
				title: "Diplom",
				defaultValue: undefined
			},
			attachment_education_mzcr: {
				title: "Rozhodnutí MZČR",
				defaultValue: undefined
			},
			attachment_education_czech: {
				title: "Úřední překlad dokladu do češtiny",
				defaultValue: undefined
			},

			stay_extend: {
				title: "Pobyt na území ČR prodloužen do",
				defaultValue: ""
			},
			attachment_stay_extend: {
				title: "Jako doklad k žádosti přikládám",
				defaultValue: undefined
			},

			new_nationality: {
				title: "Státní příslušnost",
				defaultValue: ""
			},
			attachment_new_nationality: {
				title: "Jako doklad k žádosti přikládám",
				defaultValue: undefined
			},
			doctor_or_absolvent: {
				title: "",
				defaultValue: "absolvent"
			},
			loaded: {
				defaultValue: false,
				title: ""
			}

		}
	};
}

/**
 * Definice polí fomuláře adresy
 */
export interface AddressFormFields {
	street: string;
	city: string;
	zip: string;
	country?: string;
}

function createAddressFormOptions(required: boolean): forms.FormOptions<AddressFormFields> {
	return {
		fields: {
			street: {
				title: "Ulice a číslo",
				defaultValue: "",
				required: required,
				validate: value => required && value.trim().length === 0 ? "Zadejte prosím ulici a číslo" : ""
			},
			city: {
				title: "Obec",
				defaultValue: "",
				required: required,
				validate: value => required && value.trim().length === 0 ? "Zadejte prosím obec" : ""
			},
			zip: {
				title: "PSČ",
				defaultValue: "",
				required: required,
				validate: value => required && value.trim().length === 0 ? "Zadejte prosím PSČ" : value.trim().length == 5 ? "" : value.trim().length != 5 && value.trim().length != 0 ? "PSČ musí obsahovat 5 znaků" : ""
			},
		}
	};
}


/**
 * Definice polí fomuláře zdravotnického zařízení
 */
export interface WorkPlaceFormFields {
	host_chamber?: api.CodeBookItem;
	workplace?: apiWorkplace.Workplace;
	workplace_new_name: string;
	workplace_new_provider: string;
	workplace_new_provider_address_street: string;
	workplace_new_provider_address_city: string;
	workplace_new_provider_address_postcode: string;
	workplace_new_address_street: string;
	workplace_new_address_city: string;
	workplace_new_address_postcode: string;
	workplace_new_contact_phone: string;
	workplace_new_contact_email: string;
	workplace_new_ico: string;
	certificate_file?: apiFile.File;
	certificate_doctor_name: string;
	certificate_name: string;
	date: string;
	relationship_legal_person: boolean;
	relationship_statutory_authority: boolean;
	relationship_osvc: boolean;
	relationship_employee: boolean;
	relationship_professional_representative: boolean;
	relationship_chief_physician: boolean;
	relationship_primary: boolean;
	request_workplace_type?: "existing_workplace" | "hosting_workplace" | "not_found";
	announcement: boolean;
	attachments: apiFile.FileFormFields[];
	date_of_entry_into_force: string;
	date_of_validity_of_the_amendments: string;
	visiting_only_emergency_regional_chambers: boolean;
}

/**
 * Definice fomuláře zdravotnického zařízení
 */
const WorkPlaceFormOptions: forms.FormOptions<WorkPlaceFormFields> = {
	fields: {
		workplace: {
			title: "Vyberte poskytovatele zdravotní péče a pracoviště (nově ohlašované - dle IČO, názvu, adresy).",
			defaultValue: undefined,
			onChange: (value, field, form) => form.setField("host_chamber", value == null ? undefined : value.regional_chamber!),
		},
		visiting_only_emergency_regional_chambers: {
			title: "Hostující oblasti - jen pohotovost",
			defaultValue: false
		},
		workplace_new_provider: {
			title: "Název poskytovatel",
			defaultValue: ""
		},
		date_of_entry_into_force: {
			title: "Datum nabytí právní moci Rozhodnutí",
			defaultValue: ""
		},
		date_of_validity_of_the_amendments: {
			title: "Datum platnosti změn uvedené v Rozhodnutí (je-li uvedeno)",
			defaultValue: ""
		},
		workplace_new_provider_address_street: {
			title: "Ulice a číslo (sídlo poskytovatele)",
			defaultValue: ""
		},
		workplace_new_provider_address_city: {
			title: "Obec (sídlo poskytovatele)",
			defaultValue: ""
		},
		workplace_new_provider_address_postcode: {
			title: "PSČ (sídlo poskytovatele)",
			defaultValue: "",
		},
		workplace_new_name: {
			title: "Název pracoviště",
			defaultValue: ""
		},
		workplace_new_address_street: {
			title: "Ulice a číslo (pracoviště)",
			defaultValue: ""
		},
		workplace_new_address_city: {
			title: "Obec (pracoviště)",
			defaultValue: ""
		},
		workplace_new_address_postcode: {
			title: "PSČ (pracoviště)",
			defaultValue: "",
		},
		workplace_new_contact_phone: {
			title: "Telefon (pracoviště)",
			defaultValue: "",
			placeHolderText: "+420 XXX XXX XXX",
		},
		workplace_new_contact_email: {
			title: "E-mail (pracoviště)",
			defaultValue: ""
		},
		workplace_new_ico: {
			title: "IČO",
			defaultValue: ""
		},
		certificate_doctor_name: {
			title: "Lékař",
			defaultValue: ""
		},
		certificate_name: {
			title: "Název rozhodnutí",
			defaultValue: ""
		},
		date: {
			title: "ke dni",
			defaultValue: "",
			validate: value => formatting.getDateBefore(new Date(), -15) > new Date(value) ? "Datum nesmí být menší než 14 dní" : ""
		},
		host_chamber: {
			title: "",
			defaultValue: undefined,
			readOnly: (form) => form.getField("request_workplace_type").value == "not_found" ? false : true,
		},
		relationship_legal_person: {
			title: "Společník nebo člen právnické osoby poskytovalele",
			defaultValue: false
		},
		relationship_statutory_authority: {
			title: "Statutární orgán nebo člen statut. orgánu (např. jednatel)",
			defaultValue: false
		},
		relationship_osvc: {
			title: "OSVČ",
			defaultValue: false
		},
		relationship_employee: {
			title: "Zaměstnanec",
			defaultValue: false
		},
		relationship_professional_representative: {
			title: "Odborný zástupce",
			defaultValue: false
		},
		relationship_chief_physician: {
			title: "Vedoucí lékař",
			defaultValue: false
		},
		relationship_primary: {
			title: "Primář",
			defaultValue: false
		},
		request_workplace_type: {
			title: "",
			defaultValue: "existing_workplace"
		},
		announcement: {
			title: "Prohlašuji, že na uzemí této nové hostující Oblastní komory vykonávám pouze pohotovostní služby",
			defaultValue: false
		},
		attachments: {
			title: "",
			defaultValue: []
		}
	}
};

/**
 * Pomocná metoda pro validaci nově zadávaného pracoviště
 */
function validateRequriedWorkplaceTextField(
	form: forms.Form<WorkPlaceFormFields>,
	value: string, message: string,
	validateFormat: (value: string) => string = value => "") {

	const selectedWorkplace = form.getField("workplace").value;
	return selectedWorkplace === undefined && value.trim().length === 0 ? message : validateFormat(value);
}

async function revalidateWorkplace(form: forms.Form<WorkPlaceFormFields>) {
	if (form.validated()) {
		await form.validate();
	}
}

/**
 * Definice polí souboru přílohy
 */
export interface FilesFormFields {
	file?: apiFile.File;
}

function createFilesFormOptions(required?: () => boolean): forms.FormOptions<FilesFormFields> {
	return {
		fields: {
			file: {
				title: "",
				defaultValue: undefined,
				validate: value => required && required() && value === undefined ? "Nahrajte prosím povinnou přílohu" : ""
			}
		}
	};
}

/**
 * Struktura pro odeslání/načtení dat
 */
interface FormData extends FormFields {
	address: AddressFormFields;
	contact_address: AddressFormFields;
	workplaces: WorkPlaceFormFields[];
	attachments_medical_certificates: FilesFormFields[];
	attachments_integrity_confirmations: FilesFormFields[];
	attachments_non_czech_degrees: FilesFormFields[];
	attachments_non_czech_educations: FilesFormFields[];
	attachments_degree: FilesFormFields[];
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	stateContainer: state.StateContainer<State>;
	public common: webRequest.Model;
	public form: forms.Form<FormFields>;
	public formAddress: forms.Form<AddressFormFields>;
	public formContactAddress: forms.Form<AddressFormFields>;
	public formsWorkplaces: forms.FormCollection<WorkPlaceFormFields>;

	public formsAttachmentsMedicalCertificates: forms.FormCollection<FilesFormFields>;
	public formsAttachmentsIntegrityConfirmations: forms.FormCollection<FilesFormFields>;
	public formsAttachmentsNonCzechDegrees: forms.FormCollection<FilesFormFields>;
	public formsAttachmentssNonCzechEducations: forms.FormCollection<FilesFormFields>;
	public formsAttachmentDegree: forms.FormCollection<FilesFormFields>;

	constructor(private context: context.StateContext) {
		this.common = new webRequest.Model(context);
		this.form = new forms.Form<FormFields>(createFormOptions(this), context);
		this.formAddress = new forms.Form<AddressFormFields>(createAddressFormOptions(true), context, this.form);
		this.formContactAddress = new forms.Form<AddressFormFields>(createAddressFormOptions(false), context, this.form);
		this.formsWorkplaces = new forms.FormCollection(WorkPlaceFormOptions, this.context, this.form);

		this.formsAttachmentsMedicalCertificates = new forms.FormCollection(createFilesFormOptions(), context, this.form);
		this.formsAttachmentsMedicalCertificates.addWithOptions(createFilesFormOptions(() => true));
		this.formsAttachmentsIntegrityConfirmations = new forms.FormCollection(createFilesFormOptions(), context, this.form);
		this.formsAttachmentsIntegrityConfirmations.addWithOptions(createFilesFormOptions(() => true));
		this.formsAttachmentsNonCzechDegrees = new forms.FormCollection(createFilesFormOptions(), context, this.form);
		this.formsAttachmentsNonCzechDegrees.addWithOptions(createFilesFormOptions(() => this.requireAttachmentNonCzechDegreeAndEducation()));
		this.formsAttachmentssNonCzechEducations = new forms.FormCollection(createFilesFormOptions(), context, this.form);
		this.formsAttachmentssNonCzechEducations.addWithOptions(createFilesFormOptions(() => this.requireAttachmentNonCzechDegreeAndEducation()));
		this.formsAttachmentDegree = new forms.FormCollection(createFilesFormOptions(), context, this.form);
		this.formsAttachmentDegree.addWithOptions(createFilesFormOptions(() => false));

		this.formsWorkplaces.add();

		this.stateContainer = new state.StateContainer<State>({
			countries: [],
			pedagogical_titles: [],
			titles_after: [],
			titles_before: [],
			originalFields: this.form.getFields(),
		}, context);

	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => {
		return [
			this.stateContainer,
			...this.common.getStateContainers(),
			...this.form.getStateContainers()
		];
	}

	/**
	 * Odebere formulář zdravotnického zařízení 
	 */
	removeWorkplace = async (formWorkplace: forms.Form<WorkPlaceFormFields>) => {
		if (await this.context.standardDialogs.openYesNoDialog("Smazat poskytovatele zdravotní péče?") === "yes") {
			this.formsWorkplaces.remove(formWorkplace);
		}
	}

	loadWorkplaces = async (prefix: string): Promise<apiWorkplace.Workplace[]> => {
		const workplaces = await this.context.apiWorkplace.standard.loadList({
			filter: prefix.length > 0
				? [
					api.qpOr([
						api.qp("name", "=", "*" + prefix + "*"),
						api.qp("provider.registration_number" as any, "=", prefix),
						api.qp("address.street" as any, "=", prefix + "*"),
						api.qp("address.city" as any, "=", prefix + "*")
					])
				]
				: []
		});

		return workplaces.data;
	}

	/**
	 * Vrací variantu zobrazení příloh podle zadaní
	 */
	getAttachmentsVariant = (): AttachmentsVariant => {
		const fields = this.form.getFields();
		if (fields.certificated_in === "cr" && fields.membership_eu === "eu" && fields.nationality?.code === CZECH_NATIONALITY_CODE) {
			return 1;
		} else if (fields.certificated_in === "cr" && fields.membership_eu === "eu" && fields.nationality?.code !== CZECH_NATIONALITY_CODE) {
			return 2;
		} else if (fields.certificated_in === "out-of-cr" && fields.membership_eu === "eu") {
			return 3;
		} else {
			return 4;
		}
	}

	/**
	 * Podmínka pro zobrazení přílohy českého diplomu
	 */
	requireAttachmentDegree = () => {
		const attachmentVariant = this.getAttachmentsVariant();
		return (attachmentVariant === 1 || attachmentVariant === 2) && (this.checkChangedField("title_before") == "" && this.checkChangedField("title_after") == "" && this.checkChangedField("edu_title") == "");
	}

	/**
	 * Podmínka pro zobrazení přílohy dokladu o vždělání mimo ČR
	 */
	requireAttachmentNonCzechDegreeAndEducation = () => {
		const attachmentVariant = this.getAttachmentsVariant();
		return attachmentVariant === 3 || attachmentVariant === 4;
	}

	/**
	 * Podmínka pro přílohu povolení k pobytu 
	 */
	requireAttachmentResidencePermit = () => {
		const attachmentVariant = this.getAttachmentsVariant();
		return attachmentVariant === 2 || attachmentVariant === 4;
	}

	/**
	 * Podmínka pro přílohu uznání způsobilosti k výkonu povolání
	 */
	requireAttachmentProfessionPermit = () => {
		const attachmentVariant = this.getAttachmentsVariant();
		return attachmentVariant === 3 || attachmentVariant === 4;
	}

	getCountries = () => {
		return this.stateContainer.get().countries;
	}

	setDefaultRegistrationNumber = async (birthdate: Date, gender?: number) => {
		await this.form.setField("rc", formatting.generateRegistrationNumber(birthdate, gender == undefined ? 1 : gender));
	}


	/**
	 * Načte seznam zemí pro dropdown titulů
	 */
	loadTitlesBefore = async () => {
		const titlesBefore = await this.context.api.post("/code-list/title_befores", {}) as any;
		const data = titlesBefore.data;
		data.unshift({ id: api.emptyGuid, name: "" });
		await this.stateContainer.merge(_ => ({
			titles_before: data
		}));
	}

	getTitlesBefore = () => {
		return this.stateContainer.get().titles_before;
	}

	/**
	 * Načte seznam zemí pro dropdown titulů
	 */
	loadTitleAfter = async () => {
		const titlesAfter = await this.context.api.post("/code-list/title_afters", {}) as any;
		const data = titlesAfter.data;
		data.unshift({ id: api.emptyGuid, name: "" });
		await this.stateContainer.merge(_ => ({
			titles_after: data
		}));
	}

	getTitlesAfter = () => {
		return this.stateContainer.get().titles_after;
	}

	/**
	 * Načte seznam zemí pro dropdown titulů
	 */
	loadTitlesPedagogical = async () => {
		const pedagogicalTitles = await this.context.api.post("/code-list/title_pedagogicals", {}) as any;
		const data = pedagogicalTitles.data;
		data.unshift({ id: api.emptyGuid, name: "" });
		await this.stateContainer.merge(_ => ({
			pedagogical_titles: data
		}));
	}

	getTitlesPedagogical = () => {
		return this.stateContainer.get().pedagogical_titles;
	}

	checkChangedField = (field: keyof FormFields) => {
		const value = this.form.getFields()[field];
		const originalValue = this.stateContainer.get().originalFields![field];
		return originalValue == value ? "" : "bg-input-changed";
	}

	confirm = async (restore?: boolean) => {
		await this.form.validate();
		if (this.form.isValid()) {
			const data: FormData = {
				...this.form.getFields(),
				address: this.formAddress.getFields(),
				contact_address: this.formContactAddress.getFields(),
				workplaces: this.formsWorkplaces.get().map(form => form.getFields()),
				attachments_medical_certificates: this.formsAttachmentsMedicalCertificates.get()
					.filter(form => form.getField("file").value !== undefined)
					.map(form => form.getFields()),
				attachments_integrity_confirmations: this.formsAttachmentsIntegrityConfirmations.get()
					.filter(form => form.getField("file").value !== undefined)
					.map(form => form.getFields()),
				attachments_non_czech_degrees: this.formsAttachmentsNonCzechDegrees.get()
					.filter(form => form.getField("file").value !== undefined)
					.map(form => form.getFields()),
				attachments_non_czech_educations: this.formsAttachmentssNonCzechEducations.get()
					.filter(form => form.getField("file").value !== undefined)
					.map(form => form.getFields()),
				attachments_degree: this.formsAttachmentDegree.get()
					.filter(form => form.getField("file").value !== undefined)
					.map(form => form.getFields()),
			};
			await this.common.send(data, "zadost-o-obnoveni-clenstvi");
		} else {
			await this.context.standardDialogs.openInformationDialog("Formulář obsahuje nevyplněné nebo chybně zadané údaje. Po opravě prosím akci proveďte znovu.");
		}
	}

	validateMissingProviderRelationschip = (form: forms.Form<WorkPlaceFormFields>) => {
		const fields = form.getFields();
		if (form.validated() && !(fields.relationship_chief_physician
			|| fields.relationship_employee
			|| fields.relationship_legal_person
			|| fields.relationship_osvc
			|| fields.relationship_primary
			|| fields.relationship_professional_representative
			|| fields.relationship_statutory_authority))
			return "Vyberte prosím alespoň jeden vztah k poskytovateli";

		return "";
	}

	loadData = async (webRequestId?: string) => {
		if (webRequestId) {
			await this.common.loadData(webRequestId);
			const webRequest = this.common.getWebRequest();
			await this.common.loadData(webRequestId);
			if (webRequest) {
				const {
					address,
					contact_address,
					workplaces,
					attachments_medical_certificates,
					attachments_integrity_confirmations,
					attachments_non_czech_degrees,
					attachments_non_czech_educations,
					attachments_degree,
					...rest
				} = webRequest.data as FormData;

				await Promise.all([
					this.form.setFields(rest),
					this.formAddress.setFields(address),
					this.formContactAddress.setFields(contact_address),
					this.formsWorkplaces.setFields(workplaces),
					this.formsAttachmentsMedicalCertificates.setFields(attachments_medical_certificates),
					this.formsAttachmentsIntegrityConfirmations.setFields(attachments_integrity_confirmations),
					this.formsAttachmentsNonCzechDegrees.setFields(attachments_non_czech_degrees),
					this.formsAttachmentssNonCzechEducations.setFields(attachments_non_czech_educations),
					this.formsAttachmentDegree.setFields(attachments_degree)
				]);
			}
			const countries = await this.context.apiCountry.loadCountries();
			await this.stateContainer.merge(_ => ({
				countries: countries.data
			}));
			await this.loadTitleAfter();
			await this.loadTitlesBefore();
			await this.loadTitlesPedagogical();
			await this.form.setField("loaded", true);
		}
	}

	loadDefaultData = async () => {
		const user = this.context.authorization.getUserProfile() ?? await this.context.authorization.loadUserProfile();
		const countries = await this.context.apiCountry.loadCountries();
		await this.stateContainer.merge(_ => ({
			countries: countries.data
		}));
		await this.loadTitleAfter();
		await this.loadTitlesBefore();
		await this.loadTitlesPedagogical();

		const czechCountry = countries.data.find(i => i.code === CZECH_NATIONALITY_CODE);
		if (czechCountry) {
			await this.form.setField("nationality", czechCountry);
		}

		await this.form.setField("title_after", user.profile.object_title_after);
		await this.form.setField("title_before", user.profile.object_title_before);
		await this.form.setField("edu_title", user.profile.object_title_pedagogical);

		const formFields: FormFields = {
			...this.form.getFields(),
			first_name: user.profile.first_name ?? "",
			last_name: user.profile.last_name ?? "",
			phone: user.profile.contact!.phone1 as any ?? "",
			email: user.profile.contact!.email1 as any ?? "",
			gender: user.profile.gender == undefined ? "female" : (user.profile.gender.id == 1 ? "female" : "male"),
			birth_date: formatting.formatDate(user.profile.birthdate),
			graduated_date: formatting.formatDate(user.profile.graduated_date),
			university_name: user.profile.school,
			graduated_diploma_number: user.profile.diploma_number,
			rc: user.profile.personal_identification_number,
			new_education_oral_maxifacial_chirurgy: user.profile.new_education_oral_maxifacial_chirurgy,
			next_education_atest_1: user.profile.next_education_atest_1,
			next_education_atest_2: user.profile.next_education_atest_2,
			specialization_ortodoncy: user.profile.specialization_ortodoncy,
			specialization_surgery: user.profile.specialization_surgery
		};

		const addressFormFields: AddressFormFields = {
			street: user.profile.address?.street ?? "",
			city: user.profile.address?.city ?? "",
			country: user.profile.address?.country?.name ?? "",
			zip: user.profile.address?.postcode == undefined ? "" : user.profile.address?.postcode.replace(/\s+/g, ""),
		};

		const addressDeliveryAddressFormFields: AddressFormFields = {
			street: user.profile.delivery_address?.street ?? "",
			city: user.profile.delivery_address?.city ?? "",
			country: user.profile.delivery_address?.country?.name ?? "",
			zip: user.profile.delivery_address?.postcode == undefined ? "" : user.profile.delivery_address?.postcode.replace(/\s+/g, ""),
		};

		await Promise.all([
			this.form.setFields(formFields),
			this.formAddress.setFields(addressFormFields),
			this.formContactAddress.setFields(addressDeliveryAddressFormFields),
			this.stateContainer.merge(_ => ({ originalFields: this.form.getFields() })),
		]);

		await this.form.setField("loaded", true);

	}

	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
			this.common.reset(),
			this.loadDefaultData(),
		]);
	}
}